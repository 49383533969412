<template>
  <div id="app" class="bg-light" >
    <Header id="header" />
    <router-view class="min-vh-100" />
    <Footer class="mt-auto" />
    <b-sidebar id="contact-sidebar" sidebar-class="contact-bar" right backdrop>
      <Contact />
    </b-sidebar>
  </div>
</template>
<script>
import Header from './Header.vue'
import Footer from './Footer.vue'
import Contact from '../components/contact'
export default {
  components: {
    Header,
    Footer,
    Contact
  },
  watch: {
      '$route' (to) {
          if(['home', 'mobile', 'web', 'uiux', 'maintenance'].indexOf(to.name) > -1)
            document.getElementById('app').style.marginTop = '0px';
          else
            document.getElementById('app').style.marginTop = '60px';
      }
  },
  mounted() {
    if(['home', 'mobile', 'web', 'uiux', 'maintenance'].indexOf(this.$route.name) > -1)
      document.getElementById('app').style.marginTop = '0px';
    else
      document.getElementById('app').style.marginTop = '60px';
  }
}
</script>
<style>
body,
html {
  width: 100%;
}
#app {
  font-family: 'Roboto', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  font-size: 1.125em;
}
.section {
  min-height: 80vh;
}
.leaf-card {
  border-radius: 1.75em;
  border-top-right-radius: .5em;
  border-bottom-left-radius: .5em;
  transition: all .25s;
}
.leaf-card:hover {
  transform: translateY(-.5em);
}
</style>

<style>
/* contact us button  */
.rounded-custom-5 {
    border-radius: 5em;
}
.rounded-custom-1 {
    border-radius: 1em;
}
.outline-primary {
    color: #5874c9;
    border: 2px solid #5874c9;
}
.outline-primary:hover {
    color: white;
    background: #5874c9;
}
.outline-light {
    color: white;
    border: 2px solid white;
}
.outline-light:hover {
    color: #5874c9;
    background-color: white;
}
.contact-btn {
    height: 45px;
    width: 130px;
    color: #5874c9;
    border-radius: 5em;
    align-self: flex-end;
    border: 2px solid #5874c9;
}
.contact-btn:hover {
    color: white;
    background: #5874c9;
}
.contact-bar {
    max-width: 600px;
    width: 100%;
}
</style>

<style>
/* Fonts */
.header-1 {
  font-family: 'Lato';
  font-weight: 900;
  font-size: 2.5em;
}
.header-2 {
  font-family: 'Lato';
  font-weight: 900;
  font-size: 1.25em;
}
</style>

<style>
/* Colors */
.text-primary {
  color: #5874c9 !important;
}
.bg-primary {
  background-color: #5874c9 !important;
}
.bg-primary-gradient {
  background: linear-gradient(120deg, #5874c9 30%, #90CAF9);
}
.border-light-custom{
  border-color: #2c3e50;

}
</style>

<style> 
/* Animation */
.bounce-enter-active {
  animation: bounce-in .5s;
}
.bounce-leave-active {
  animation: bounce-in .5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
.bounce-modal.show {
    animation: bounce-in .5s;
}
</style>