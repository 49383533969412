<template>

    <div class="mx-auto w-100" style="width: 95vw !important; max-width: 600px;">
        <div class="flex-grow-1 shadow mx-3">
            <b-progress height="5em" :variant="bar.variant">
                <b-progress-bar :value="bar.value">
                    <span class="font-weight-bold h6"> {{labels[0]}} </span>
                </b-progress-bar>
                <b-progress-bar :value="100 - bar.value" variant="light">
                    <span class="font-weight-bold text-muted h6"> {{labels[1]}} </span>
                </b-progress-bar>
            </b-progress>
        </div>

        <div class="my-4 text-center">
            <span class="btn text-muted mx-2 p-0" :class="{'font-weight-bold text-primary': selected=='en'}" @click="changeLang('en')">English</span>
            <span class="btn text-muted mx-2 p-0" :class="{'font-weight-bold text-primary': selected=='am'}" @click="changeLang('am')">አማርኛ</span>
            <span class="text-muted mx-3 small font-weight-bold d-inline-flex align-items-center"> <i class="fa fa-ellipsis-h"></i> <span class="mx-1">More</span></span>
        </div>
    </div>

</template>
<script>
/* eslint-disable */
export default {
    name: "Localization",
    data() {
        return{
            selected: 'en',
            bar: { variant: 'primary', value: 50 },
            labels: [],
            labelsEN: ["Inflation", "Value of Money"],
            labelsAM: ["የዋጋ ግሽበት", "የመግዛት አቅም"],
            timer: null
        }
    },
    mounted() {
        this.labels = this.labelsEN;
        this.timer = setInterval(() => {
            this.bar.value = 25 + Math.random() * 75;
            if(this.bar.value > 65)
                this.bar.variant = "danger";
            else if(this.bar.value > 35)
                this.bar.variant = "primary";
            else if(this.bar.value <= 35)
                this.bar.variant = "success";
        }, 2000);
    },
    beforeDestroy() {
      clearInterval(this.timer)
      this.timer = null
    },
    methods: {
        changeLang(lang) {
            if(lang == 'en'){
                this.labels = this.labelsEN;
            } else if(lang == 'am'){
                this.labels = this.labelsAM;
            }
            this.selected = lang;
        }
    }
}
</script>

<style>

</style>