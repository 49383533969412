import Vue from 'vue'
import VueRouter from 'vue-router'

import Root from '../views/Root.vue'
import Home from '../components/home.vue'
import Mobile from '../components/mobile.vue'
import Web from '../components/web.vue'
import UiUx from '../components/uiux.vue'
import Modernization from '../components/modernization.vue'
import Maintenance from '../components/maintenance.vue'
import Playground from '../components/playground.vue'
import Portfolio from '../components/portfolio.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Root,
    children: [
      {
        path: '/',
        redirect: '/home'
      },
      {
        path: '/home',
        name: 'home',
        component: Home
      },
      {
        path: '/mobile',
        name: 'mobile',
        component: Mobile
      },
      {
        path: '/web',
        name: 'web',
        component: Web
      },
      {
        path: '/uiux',
        name: 'uiux',
        component: UiUx
      },
      {
        path: '/modernization',
        name: 'modernization',
        component: Modernization
      },
      {
        path: '/maintenance',
        name: 'maintenance',
        component: Maintenance
      },
      {
        path: '/playground',
        name: 'playground',
        component: Playground
      },
      {
        path: '/portfolio',
        name: 'portfolio',
        component: Portfolio
      },
    ]
  }
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes
})

export default router
