<template>
    <footer class="text-white text-left d-flex flex-column">

        <div class="d-inline-flex flex-wrap px-5 py-4 mx-auto">
            <div class="mx-5">
                <h5>Expertise</h5>
                <ul class="d-flex flex-column align-items-start">
                    <router-link class="my-2" to="/mobile" active-class="btn p-0 disabled"> <i v-if="$route.path=='/mobile'" class="fa fa-hand-point-right"></i> Mobile Development </router-link>
                    <router-link class="my-2" to="/web" active-class="btn p-0 disabled"> <i v-if="$route.path=='/web'" class="fa fa-hand-point-right"></i> Web Development </router-link>
                    <router-link class="my-2" to="/uiux" active-class="btn p-0 disabled"> <i v-if="$route.path=='/uiux'" class="fa fa-hand-point-right"></i> UI/UX Design </router-link>
                </ul>
            </div>

            <div class="mx-5">
                <h5>More</h5>
                <ul class="d-flex flex-column align-items-start">
                    <router-link class="my-2" to="/modernization" active-class="btn p-0 disabled"> <i v-if="$route.path=='/modernization'" class="fa fa-hand-point-right"></i> App Modernization </router-link>
                    <router-link class="my-2" to="/maintenance" active-class="btn p-0 disabled"> <i v-if="$route.path=='/maintenance'" class="fa fa-hand-point-right"></i> Maintenance </router-link>
                    <router-link class="my-2" to="/portfolio" active-class="btn p-0 disabled"> <i v-if="$route.path=='/portfolio'" class="fa fa-hand-point-right"></i> Portfolio </router-link>
                    <router-link class="my-2" to="/playground" active-class="btn p-0 disabled"> <i v-if="$route.path=='/playground'" class="fa fa-hand-point-right"></i> Playground </router-link>
                </ul>
            </div>

            <div class="mx-auto">
                <h5>Contact Info</h5>
                <ul>
                    <li class="d-flex align-items-center"> 
                        <i class="fa fa-envelope mr-3"></i>
                        <div>contact@derashtech.com</div>
                    </li>
                    <li class="d-flex align-items-center"> 
                        <i class="fa fa-phone mr-3"></i>
                        <div>+251-925-684609 <br> +251-991-134829 </div> 
                    </li>
                    <li class="d-flex align-items-center"> 
                        <i class="fa fa-map-marker-alt mr-3"></i>
                        <div>
                            Adore, 2<sup>nd</sup> Floor, <br> 
                            ( next to Desalegne Hotel ) <br> 
                            Atlas Bole, Addis Ababa 
                        </div>
                    </li>
                </ul>
                <b-button v-b-toggle.contact-sidebar variant="outline" class="contact-btn font-weight-bold small px-3 mx-0"> Contact Us </b-button>
            </div>

        </div>

        <div class="border-top border-secondary text-secondary text-center py-3 mx-4">
            <small> &copy;{{ (new Date()).getFullYear() }} Derash Technologies PLC. All Rights Reserved </small>
        </div>

    </footer>
</template>

<script>
export default {
    name: 'Footer'
}
</script>

<style scoped>
ul {
    list-style: none;
    padding-left: 0;
    margin-left: 0;
}
footer {
    background: #2d313d;
}
.footer-non-link-item {
    color: #FFF;
}
h5 {
    font-weight: 700;
    line-height: 24px;
    color: #FFF;
    margin-block-start: 1.67em;
    margin-block-end: 1.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;

    font-size: 1rem;
    margin-bottom: 14px;
}
ul li {
    line-height: 25px;
    margin-bottom: 10px;
    position: relative;
    margin: .75em auto;
}
a {
    position: relative;
    color: #f0f0f0;
    font-size: 14px;
    text-decoration: none;
}

a:after {    
    background: none repeat scroll 0 0 transparent;
    bottom: -5px;
    content: "";
    display: block;
    height: 2px;
    left: 50%;
    position: absolute;
    background: #F0F0F0;
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
}
a:hover:after {
    width: 100%;
    left: 0;
}
small {
    font-size: 14px;
    line-height: 1.5;
}
.fa{
    font-size: 1.2em;
}
.social .fa {
    font-size: 2em;
}
.social .fa:hover {
    color: #5874c9;
}
</style>