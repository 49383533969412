<template>

    <div id="section-1" class="col-12 mx-auto mb-md-5 pb-md-5 px-0">

        <div class="section d-flex align-items-center justify-content-center flex-wrap py-5 pr-md-5">
            <b-card
                style="max-width: 600px; min-width: 360px; border-radius: 2em;"
                class="border-0 text-left mx-5 p-md-5" >
                <b-card-title class="header-1 mb-4"> Your Trusted Development Partner </b-card-title>
                <b-card-text class="mb-4">
                    We’re a software development company specialized in <b>Mobile</b> and 
                    <b>Web</b> based solutions. We're also great at <b>UI/UX</b> design. 
                    Based in Ethiopia, we’ve been experimenting with cutting-edge technologies 
                    and challenging the status quo.
                </b-card-text>

                <b-button v-b-toggle.contact-sidebar variant="outline" class="contact-btn my-auto font-weight-bold small px-3"> Contact Us </b-button>
            </b-card>
        </div>

        <div class="section d-flex align-items-center justify-content-center flex-wrap">
            <p class="col-12 header-1 my-5 p-0">Our Services</p>

            <b-card
                style="font-weight: 300; max-width: 350px;"
                body-class="d-flex flex-column"
                class="border-0 shadow leaf-card text-left mx-3 my-4 p-3" >
                <b-card-title class="header-2 mb-3 d-flex align-items-center">
                    <i class="fa fa-mobile text-primary mr-3" style="font-size: 2em;"></i> 
                    <span>Mobile development</span> 
                </b-card-title>
                <b-card-text class="mb-4 flex-grow-1">
                    Tailor-made solutions for both <b>IOS</b> and <b>Android</b>. Having huge expertise and 
                    experience in <b>Native</b>, <b>Cross-platform</b>, and <b>Hybrid development</b>, we deliver 
                    innovative mobile apps with an impeccable architecture and sharp design.
                </b-card-text>

                <b-link to="/mobile" class="card-link ml-auto"> <i class="fa fa-ellipsis-h"></i> </b-link>
            </b-card>

            <b-card
                style="font-weight: 300; max-width: 350px;"
                body-class="d-flex flex-column"
                class="border-0 shadow leaf-card text-left mx-3 my-4 p-3" >
                <b-card-title class="header-2 mb-3 d-flex align-items-center">
                    <i class="fa fa-desktop text-primary mr-3" style="font-size: 1.25em;"></i> 
                    <span>Web development</span> 
                </b-card-title>
                <b-card-text class="mb-4 flex-grow-1">
                    Our software development company provides both <b>front-end</b> and <b>back-end</b> development services. 
                    We specialize in cutting-edge technologies and frameworks to deliver you brand-new, feature-rich, 
                    adaptive, and responsive software.
                </b-card-text>

                <b-link to="/web" class="card-link ml-auto"> <i class="fa fa-ellipsis-h"></i> </b-link>
            </b-card>

            <b-card
                style="font-weight: 300; max-width: 350px;"
                body-class="d-flex flex-column"
                class="border-0 shadow leaf-card text-left mx-3 my-4 p-3" >
                <b-card-title class="header-2 mb-3 d-flex align-items-center">
                    <i class="fa fa-bezier-curve text-primary mr-3" style="font-size: 1.25em;"></i> 
                    <span>UI/UX Design</span> 
                </b-card-title>
                <b-card-text class="mb-4 flex-grow-1">
                    We create an engaging wrapping for your ideas! This is where the creative magic of 
                    UI/UX design begins - from concept to a full-fledged package of unique design attributes.
                    This is the most enjoyable and engaging part of our development cycle.
                </b-card-text>

                <b-link to="/uiux" class="card-link ml-auto"> <i class="fa fa-ellipsis-h"></i> </b-link>
            </b-card>
        </div>

        <div class="section d-flex align-items-center justify-content-center flex-wrap">
            <b-card
                bg-variant="transparent"
                style="max-width: 700px; min-width: 360px; "
                class="border-0 text-left mx-auto mx-md-0" >
                <b-card-title class="header-1 mb-4"> Startup Development </b-card-title>
                <b-card-text class="mb-4">
                    MVP enables our customers to test if their idea works in the market 
                    by receiving user feedback as soon as possible, and skip or quickly 
                    change the course of development accordingly.
                </b-card-text>
                
                <b-card-title class="header-2 mb-4 text-primary"> Great solution for: </b-card-title>

                <div class="d-flex flex-wrap align-items-center justify-content-between">
                    <div class="mx-2 mb-4">
                        <b-card-title class="header-2 mb-4"> <i class="fa fa-lightbulb mr-2"></i> Startups </b-card-title>
                        <div><i class="fa fa-hand-point-right"></i> Test a new niche</div>
                        <div><i class="fa fa-hand-point-right"></i> To validate the feasibility of an idea</div>
                    </div>
                    <div class="mx-2 mb-4">
                        <b-card-title class="header-2 mb-4"> <i class="fa fa-rocket mr-2"></i> Innovative SMBs  </b-card-title>
                        <div><i class="fa fa-hand-point-right"></i> Service improvement</div>
                        <div><i class="fa fa-hand-point-right"></i> Expansion of the influence zone</div>
                    </div>
                </div>
            </b-card>

            <div class="mx-3 my-5 w-100" style="max-width: 35em;">
            <!-- <div class="mx-3 mx-md-0 my-5" style="max-width: 500px;"> -->
                <!-- <b-img src="@/assets/undraw_4.svg" class="col-12" /> -->
                <b-img src="@/assets/launch.svg" />
                <p class="my-3 text-primary">Test your ideas in just 3 months</p>
            </div>
        </div>

        <div class="section d-flex align-items-stretch justify-content-center flex-wrap py-5">
            <p class="col-12 header-1 my-5 px-5">We Simplify Software Development Process</p>

            <b-card
                bg-variant="transparent"
                style="font-weight: 300; max-width: 300px;"
                body-class="d-flex flex-column"
                class="border-0 text-left mx-1 my-4 p-0" >
                <div class="position-relative">
                    <i class="fab fa-searchengin text-primary" style="font-size: 3em;"></i>
                    <span style="border: 1px dashed #252b33; width: 50%; position: absolute; top: 50%; right: 12px; transform: translateY(-50%);"></span>
                </div>
                <b-card-title class="header-2 my-3 d-flex align-items-center"> Discover </b-card-title>
                <b-card-text class="mb-4 flex-grow-1">
                    We shape brands through exploration, applying in-depth research to challenge assumptions at every turn. 
                </b-card-text>
            </b-card>

            <b-card
                bg-variant="transparent"
                style="font-weight: 300; max-width: 300px;"
                body-class="d-flex flex-column"
                class="border-0 text-left mx-1 my-4 p-0" >
                <div class="position-relative">
                    <i class="fa fa-drafting-compass text-primary" style="font-size: 3em;"></i>
                    <span style="border: 1px dashed #252b33; width: 50%; position: absolute; top: 50%; right: 12px; transform: translateY(-50%);"></span>
                </div>
                <b-card-title class="header-2 my-3 d-flex align-items-center"> Design </b-card-title>
                <b-card-text class="mb-4 flex-grow-1">
                    Our design approach is to simplify. We embrace the joy in creating something unique that is easy for end users.
                </b-card-text>
            </b-card>

            <b-card
                bg-variant="transparent"
                style="font-weight: 300; max-width: 300px;"
                body-class="d-flex flex-column"
                class="border-0 text-left mx-1 my-4 p-0" >
                <div class="position-relative">
                    <i class="fa fa-laptop-code text-primary" style="font-size: 3em;"></i>
                    <span style="border: 1px dashed #252b33; width: 50%; position: absolute; top: 50%; right: 12px; transform: translateY(-50%);"></span>
                </div>
                <b-card-title class="header-2 my-3 d-flex align-items-center"> Build </b-card-title>
                <b-card-text class="mb-4 flex-grow-1">
                    Using modern technologies, we build with efficiency and skill, creating flexible and scalable business-driven solutions.
                </b-card-text>
            </b-card>

            <b-card
                bg-variant="transparent"
                style="font-weight: 300; max-width: 300px;"
                body-class="d-flex flex-column"
                class="border-0 text-left mx-1 my-4 p-0" >
                <div class="position-relative">
                    <i class="fa fa-shipping-fast text-primary" style="font-size: 3em;"></i>
                </div>
                <b-card-title class="header-2 my-3 d-flex align-items-center"> Deliver </b-card-title>
                <b-card-text class="mb-4 flex-grow-1">
                    We take an iterative approach to both our work and our practice, always looking for ways to improve what we do. 
                </b-card-text>
            </b-card>

        </div>

        <div class="section d-flex align-items-stretch justify-content-center flex-wrap bg-white py-5">
            <p class="col-12 header-1 my-4 p-0">Why Us</p>

            <div class="d-flex justify-content-center flex-wrap col-md-10">

                <div class="d-flex align-items-center align-self-center my-3">
                    <div id="leaf-1" class="leaf-card bg-light d-inline-block shadow-sm p-3" style="width: 75px; height: 65px;">
                        <i class="fa fa-user-friends text-primary" style="font-size: 2em;"></i>
                    </div>
                    <label for="leaf-1" class="p-3 text-left" style="width: 250px"> Dedicated team with versatile expertise</label>
                </div>

                <div class="d-flex align-items-center align-self-center my-3">
                    <div id="leaf-1" class="leaf-card bg-light d-inline-block shadow-sm p-3" style="width: 75px; height: 65px;">
                        <i class="fa fa-github text-primary" style="font-size: 2em;"></i>
                    </div>
                    <label for="leaf-1" class="p-3 text-left" style="width: 250px"> Clean and commented code: easy to support and re-use</label>
                </div>

                <div class="d-flex align-items-center align-self-center my-3">
                    <div id="leaf-1" class="leaf-card bg-light d-inline-block shadow-sm p-3" style="width: 75px; height: 65px;">
                        <i class="fa fa-eye text-primary" style="font-size: 2em;"></i>
                    </div>
                    <label for="leaf-1" class="p-3 text-left" style="width: 250px"> Always hunting for new technologies to make future-proof apps</label>
                </div>

                <div class="d-flex align-items-center align-self-center my-3">
                    <div id="leaf-1" class="leaf-card bg-light d-inline-block shadow-sm p-3" style="width: 75px; height: 65px;">
                        <i class="fa fa-thumbs-up text-primary" style="font-size: 2em;"></i>
                    </div>
                    <label for="leaf-1" class="p-3 text-left" style="width: 250px"> We give great advice. First few are free.</label>
                </div>

                <div class="d-flex align-items-center align-self-center my-3">
                    <div id="leaf-1" class="leaf-card bg-light d-inline-block shadow-sm p-3" style="width: 75px; height: 65px;">
                        <i class="fa fa-tachometer-alt text-primary" style="font-size: 2em;"></i>
                    </div>
                    <label for="leaf-1" class="p-3 text-left" style="width: 250px"> Prompt go-to-market: Startup Development in just 3 months</label>
                </div>

                <div class="d-flex align-items-center align-self-center my-3">
                    <div id="leaf-1" class="leaf-card bg-light d-inline-block shadow-sm p-3" style="width: 75px; height: 65px;">
                        <i class="fa fa-handshake text-primary" style="font-size: 2em;"></i>
                    </div>
                    <label for="leaf-1" class="p-3 text-left" style="width: 250px"> Progress Transparency, matured processes, effective communication</label>
                </div>

                <div class="d-flex align-items-center align-self-center my-3">
                    <div id="leaf-1" class="leaf-card bg-light d-inline-block shadow-sm p-3" style="width: 75px; height: 65px;">
                        <i class="fa fa-rocket text-primary" style="font-size: 2em;"></i>
                    </div>
                    <label for="leaf-1" class="p-3 text-left" style="width: 250px"> We'll not only assist in the development but also in the launch.</label>
                </div>

            </div>

        </div>

        <div class="section d-flex align-items-center justify-content-center flex-wrap py-5">
            <Contact class="mx-3 col-md-5" />
            <div class="mx-3 my-5 w-100" style="max-width: 35em;">
                <b-img src="@/assets/contact.svg" class="col-12" />
            </div>
        </div>

    </div>

</template>
<script>
import Contact from './contact.vue'
/* eslint-disable */
export default {
    name: "Home",
    components: {
        Contact
    },
    data() {
        return{
            
        }
    },
    mounted() {

    },
    methods: {
        
    }
}
</script>

<style scoped>
#section-1 {
    background-image: url('../assets/banner.svg');
    background-position: 0 -2em;
    background-repeat: no-repeat;
    background-size: 130%;
}
.section:first-child {
    position: relative !important;
    padding-top: 12em !important;
}
@media screen and (min-width: 768px) {
    #section-1 {
        background-position: 0 -2em;
        background-size: 100%;
    }
    .section:first-child {
        margin-top: 0px !important;
        left: 20em !important;
    }
    .section:first-child .card{
        box-shadow: 0 .5rem 1rem rgba(0,0,0,.15) !important;
    }
}
</style>