<template>
    <b-navbar toggleable="md" type="light" class="py-0" fixed="top" >

        <b-navbar-brand 
            id="navbar-brand" 
            class="font-weight-bold align-self-start ml-3 py-3 my-auto d-flex flex-column align-items-start" 
            to="/home" > 
            <div class="d-flex align-items-center">
                <b-img src="@/assets/d.svg" height="30px" id="logo-d" class="mr-1" /> 
                <span class=" header-2" style="line-height: 30px;">erash</span>
                <span class="mx-1 mt-1">|</span>
                <span class="small mt-2 font-weight-bold" style="line-height: 30px;"> Technologies </span>
            </div>
        </b-navbar-brand>

        <div id="nav-toggler" @click="toggleNavbar" :class="{'open': open}" v-b-toggle.nav-collapse> <span></span> <span></span> <span></span> </div>

        <b-collapse id="nav-collapse" ref="navCollapse" is-nav class="w-100">
            <b-navbar-nav class="p-0 m-0 ml-auto mr-4 d-flex h-100 text-muted nav-items">

                <b-button variant="transparent" class="font-weight-bold nav-item rounded-0 px-1 ml-3" to="/home"> Home </b-button>
                
                <div class="custom-dropdown text-left">
                    <b-button variant="transparent" class="font-weight-bold nav-item rounded-0 px-1 ml-3"> Services </b-button>
                    <div class="custom-dropdown-content bg-white py-3 px-md-5">
                        <b-button variant="transparent" class="p-0 my-2 rounded-0 sub-nav-item text-muted" active-class="text-primary disabled" to="/mobile"> <i v-if="$route.path=='/mobile'" class="fa fa-hand-point-right"></i> Mobile Development </b-button>
                        <b-button variant="transparent" class="p-0 my-2 rounded-0 sub-nav-item text-muted" active-class="text-primary disabled" to="/web"> <i v-if="$route.path=='/web'" class="fa fa-hand-point-right"></i> Web Development  </b-button>
                        <b-button variant="transparent" class="p-0 my-2 rounded-0 sub-nav-item text-muted" active-class="text-primary disabled" to="/uiux"> <i v-if="$route.path=='/uiux'" class="fa fa-hand-point-right"></i> UI/UX Design </b-button>
                        <hr class="border border-light-custom bg-light w-100 my-2">
                        <b-button variant="transparent" class="p-0 my-2 rounded-0 sub-nav-item text-muted" active-class="text-primary disabled" to="/modernization"> <i v-if="$route.path=='/modernization'" class="fa fa-hand-point-right"></i> App Modernization </b-button>
                        <b-button variant="transparent" class="p-0 my-2 rounded-0 sub-nav-item text-muted" active-class="text-primary disabled" to="/maintenance"> <i v-if="$route.path=='/maintenance'" class="fa fa-hand-point-right"></i> Maintenance </b-button>
                    </div>
                </div>

                <b-button variant="transparent" class="font-weight-bold nav-item rounded-0 px-1 ml-3" to="/portfolio"> Portfolio </b-button>

                <b-button variant="transparent" class="font-weight-bold nav-item rounded-0 px-1 ml-3" to="/playground"> Playground </b-button>
                
                <b-button v-b-toggle.contact-sidebar variant="outline" class="contact-btn my-auto font-weight-bold small px-3 ml-4"> Contact Us </b-button>

            </b-navbar-nav>
        </b-collapse>

    </b-navbar>
</template>
<script>
/* eslint-disable */
export default {
    name: "Header",
    data: () => {
        return {
            open: false,
        }
    },
    watch: {
        '$route' (to, from) {
            if(this.$refs.navCollapse.show) {
                this.$refs.navCollapse.show = false;
                this.open = false;
            }
            if(['home', 'mobile', 'web', 'uiux', 'maintenance'].indexOf(to.name) > -1) {
                document.querySelector('.navbar').classList.add('navbar-transparent');
                window.onscroll = () => {
                    if(window.scrollY < 10 && document.querySelector('.navbar')) {
                        document.querySelector('.navbar').classList.add('navbar-transparent');
                    }
                    else 
                        document.querySelector('.navbar').classList.remove('navbar-transparent');
                    
                    this.$forceUpdate();
                }
            } else {
                window.onscroll = null;
                document.querySelector('.navbar').classList.remove('navbar-transparent');
                this.$forceUpdate();
            }
        }
    },
    mounted(){
        if(['home', 'mobile', 'web', 'uiux', 'maintenance'].indexOf(this.$route.name) > -1) {
            document.querySelector('.navbar').classList.add('navbar-transparent');
            window.onscroll = () => {
                if(window.scrollY < 10 && document.querySelector('.navbar'))
                    document.querySelector('.navbar').classList.add('navbar-transparent');
                else
                    document.querySelector('.navbar').classList.remove('navbar-transparent');
            }
        }
    },
    methods: {
        toggleNavbar() {
            if(!this.open)
                document.querySelector('.navbar').classList.remove('navbar-transparent');
            else if(['home', 'mobile', 'web', 'uiux', 'maintenance'].indexOf(this.$route.name) > -1 && window.scrollY < 60)
                document.querySelector('.navbar').classList.add('navbar-transparent');

            this.open = !this.open;
        }
    }
}
</script>
<style scoped>
.navbar {
    background: #fff;
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075);
}
.navbar-transparent {
    background: #0000;
    box-shadow: unset;
}
.navbar-transparent #navbar-brand {
    color: #fff;
}
.navbar-transparent .contact-btn {
    border-color: #fff;
    color: #fff;
}
.navbar-transparent .contact-btn:hover {
    background: #fff;
    color: #6c757d;
}
.navbar-transparent #navbar-brand #logo-d {
    mix-blend-mode: hard-light;
}
.navbar-transparent .nav-item {
    color: #fff !important;
}
.navbar-transparent .nav-item::after {
    background: #fff;
}
#navbar-brand {
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
}
.nav-items {
    flex-direction: row;
}
.custom-dropdown {
    position: relative;
    display: inline-block;
}
.custom-dropdown-content {
    display: contents;   
}
.nav-item {
    position: relative;
    line-height: 60px;
    color: #6c757d;
}
.nav-item:after {
    background: none repeat scroll 0 0 transparent;
    bottom: -1px;
    content: "";
    display: block;
    height: 3px;
    left: 50%;
    position: absolute;
    background: #5874c9;
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
}
.nav-item:hover::after,
.custom-dropdown:hover .nav-item::after {
    width: 100%; 
    left: 0; 
}
.sub-nav-item {
    position: relative;
    white-space: nowrap;
}
.sub-nav-item:hover {
    color: #5874c9 !important;
}
.sub-nav-item:after {    
    background: none repeat scroll 0 0 transparent;
    bottom: 0;
    content: "";
    display: block;
    height: 2px;
    left: 50%;
    position: absolute;
    background: #5874c9;
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
}
.sub-nav-item:hover:after {
    width: 100%;
    left: 0;
}
#nav-collapse {
    transition: all .05s;
}
@media screen and (max-width: 767.9px) {
    #nav-collapse {
        padding-bottom: 1.5em;
    }
    .nav-items {
        flex-direction: column;
        width: 100%;
    }
    .nav-item {
        line-height: unset;
        align-self: flex-start;
    }
    .custom-dropdown {
        display: flex;
        flex-direction: column;
    }
}
@media screen and (min-width: 768px) {
    #nav-toggler {
        display: none;
    }
    .custom-dropdown-content {
        display: none;
        position: absolute;
        right: -5em;
        z-index: 1;
        border-bottom-left-radius: .75em;
        border-bottom-right-radius: .75em;
        box-shadow: 0 .225rem .5rem rgba(0,0,0,.1) !important;
        padding-bottom: 2em !important;
    }
    .custom-dropdown:hover .custom-dropdown-content {
        display: block;
    }
}
/*  */
#nav-toggler {
    width: 30px;
    height: 20px;
    position: relative;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
}
#nav-toggler span {
    display: block;
    position: absolute;
    height: 4px;
    width: 100%;
    background: #6c757d;
    border-radius: 2em;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
}
/*  */
#nav-toggler span:nth-child(1) {
    top: 0px;
}
#nav-toggler span:nth-child(2) {
    top: 8px;
}
#nav-toggler span:nth-child(3) {
    top: 16px;
}
/* Open */
#nav-toggler.open span:nth-child(1) {
    top: 8px;
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg);
}
#nav-toggler.open span:nth-child(2) {
    opacity: 0;
    left: -20px;
}
#nav-toggler.open span:nth-child(3) {
    top: 8px;
    -webkit-transform: rotate(-135deg);
    -moz-transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
    transform: rotate(-135deg);
}
</style>