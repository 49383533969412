<template>

    <div class="mx-md-5 px-md-5">
        <div v-if="!success">
            <p class="col-12 h2 font-weight-bold mt-3 mb-5 px-5">Time to start a new Project ?</p>

            <b-input-group class="mt-4 col-12">
                <b-form-input :disabled="submitting" class="rounded-0 border-0 shadow-sm p-4 mx-2" v-model="name" type="text" required placeholder="Full name"> </b-form-input>
            </b-input-group>
            <b-form-invalid-feedback class="text-left col-12 px-4" v-if="errors.name" :state="false">
                {{errors.name}}
            </b-form-invalid-feedback>

            <b-input-group  class="mt-4 col-12">
                <b-form-input :disabled="submitting" class="rounded-0 border-0 shadow-sm p-4 mx-2" v-model="email" type="text" required placeholder="Email"> </b-form-input>
                <b-form-input :disabled="submitting" class="rounded-0 border-0 shadow-sm p-4 mx-2" v-model="phone" type="text" required placeholder="Phone"> </b-form-input>
            </b-input-group>
            <b-form-invalid-feedback class="text-left col-12 px-4" v-if="errors.contact" :state="false">
                {{errors.contact}}
            </b-form-invalid-feedback>

            <b-input-group  class="mt-4 col-12">
                <b-form-textarea :disabled="submitting" class="rounded-0 border-0 shadow-sm p-4 mx-2" v-model="description" placeholder="What's on your mind..?" rows="5" ></b-form-textarea>
            </b-input-group>

            <b-input-group  class="mt-5 col-12">
                <b-button :disabled="submitting" @click="$refs.files.click()" variant="outline" class="outline-primary rounded-custom-5 my-auto font-weight-bold small px-3 mx-2">  Attach Files <i class="ml-1 fa fa-link"></i> </b-button>
                <b-button v-if="!submitting" @click="contact" variant="outline" class="outline-primary rounded-custom-5 my-auto font-weight-bold small px-3 mx-2 ml-auto">  Send <i class="ml-1 fa fa-send"></i> </b-button>
                <b-spinner v-else variant="primary" class="mx-2 my-2 ml-auto" style="width:20px;height:20px;" type="grow"></b-spinner>
            </b-input-group>
        </div>
        <div v-else class="my-5 py-5">
            <div class="my-5 py-5">
                <b-img src="@/assets/undraw_2_2.svg" width="250" />
                <div class="header-1 text-primary"> Thank you !</div>
                <div class="h5 font-weight-bold text-muted">We'll get back to you shortly.</div>
                <b-button @click="resetForm" variant="outline" class="outline-primary rounded-custom-5 my-3 font-weight-bold small px-3 mx-2 ml-auto"> Done </b-button>
            </div>
        </div>
        <input type="file" ref="files" multiple hidden>
    </div>

</template>

<script>
/* eslint-disable */
export default {
    name: "Contact",
    data() {
        return{
            submitting: false,
            success: false,
            name: null,
            email: null,
            phone: null,
            description: null,
            errors: {
                name: null,
                contact: null,
            }
        }
    },
    mounted() {

    },
    methods: {
        contact() {
            var self = this;
            if(!self.foundErrors()) {
                var formData = new FormData();
                formData.append("name", self.name);
                formData.append("email", self.email);
                formData.append("phone", self.phone);
                formData.append("description", self.description);
                self.$refs.files.files.forEach((file, i) => {
                    formData.append('File-'+(i+1), file);
                });
                self.submitting = true;
                $.ajax({
                    type: "POST",
                    url: "https://getform.io/f/e323229b-8e19-425a-9863-c0c10298df02",
                    crossDomain: true,
                    data: formData,
                    contentType: "multipart/form-data",
                    processData: false,
                    contentType: false,
                }).done(function() {
                    $('#contactForm').trigger("reset");
                    self.submitting = false;
                    self.success = true;
                }).fail(function(err) {
                    self.submitting = false;
                });
            }
        },
        foundErrors() {
            var err = false;
            if(!this.name || this.name && this.name.toString().trim() == "") {
                this.errors.name = "What do we call you ? Name is required.";
                err = true;
            }else{
                this.errors.name = null;
            }
            if((!this.email || this.email && this.email.toString().trim() == "") && (!this.phone || this.phone && this.phone.toString().trim() == "")) {
                this.errors.contact = "How do we contact you ? Email or Phone is required.";
                err = true;
            }else{
                this.errors.contact = null;
            }
            return err;
        },
        resetForm() {
            this.name = null;
            this.email = null;
            this.phone = null;
            this.description = null;
            this.$refs.files.values = [];
            this.success = false;
        }
    }
}
</script>

<style>

</style>