<template>

    <div class="col-12 mx-auto my-md-0 px-0">
        
        <div class="section d-flex flex-column align-items-center justify-content-center flex-wrap py-5 bg-white">
            <p class="header-2 text-muted py-0 px-0 mb-4">Feature Playground</p>
            
            <div class="d-flex align-items-start justify-content-center flex-wrap">
                <b-list-group class="mx-3 mx-md-5 d-flex flex-row flex-lg-column justify-content-center flex-wrap">
                    <b-list-group-item @click="changeSelected(0)" :active="selected==0" :class="{'bg-primary': selected==0}" class="btn border-0 d-flex flex-row align-items-center my-1" style="border-radius: 1em">
                        <div class="d-flex align-items-center mr-lg-3 bg-white" style="width:4em; height:4em; border-radius:50%;">
                            <i class="fa fa-table w-100" :class="{'text-primary': selected==0, 'text-muted': selected!=0}" style="font-size: 2.5em;"></i>
                        </div>
                        <span class="h5 d-none d-lg-block" :class="{'text-muted': selected!=0}"> Table </span>
                    </b-list-group-item>
                    
                    <b-list-group-item @click="changeSelected(1)" :active="selected==1" :class="{'bg-primary': selected==1}" class="btn border-0 d-flex flex-row align-items-center my-1" style="border-radius: 1em">
                        <div class="d-flex align-items-center mr-lg-3 bg-white" style="width:4em; height:4em; border-radius:50%;">
                            <i class="fa fa-chart-line w-100" :class="{'text-primary': selected==1, 'text-muted': selected!=1}" style="font-size: 2.5em;"></i>
                        </div>
                        <span class="h5 d-none d-lg-block" :class="{'text-muted': selected!=1}">Chart</span>
                    </b-list-group-item>
                    
                    <b-list-group-item @click="changeSelected(2)" :active="selected==2" :class="{'bg-primary': selected==2}" class="btn border-0 d-flex flex-row align-items-center my-1" style="border-radius: 1em">
                        <div class="d-flex align-items-center mr-lg-3 bg-white" style="width:4em; height:4em; border-radius:50%;">
                            <i class="fa fa-calendar w-100" :class="{'text-primary': selected==2, 'text-muted': selected!=2}" style="font-size: 2.5em;"></i>
                        </div>
                        <span class="h5 d-none d-lg-block" :class="{'text-muted': selected!=2}">Calendar</span>
                    </b-list-group-item>
                    
                    <b-list-group-item @click="changeSelected(3)" :active="selected==3" :class="{'bg-primary': selected==3}" class="btn border-0 d-flex flex-row align-items-center my-1" style="border-radius: 1em">
                        <div class="d-flex align-items-center mr-lg-3 bg-white" style="width:4em; height:4em; border-radius:50%;">
                            <i class="fa fa-language w-100" :class="{'text-primary': selected==3, 'text-muted': selected!=3}" style="font-size: 2.5em;"></i>
                        </div>
                        <span class="h5 d-none d-lg-block" :class="{'text-muted': selected!=3}">Localization</span>
                    </b-list-group-item>                    
                </b-list-group>

                <transition name="bounce" >
                    <div v-if="selected!=null" style="min-width: 60vw">
                        <b-card
                            v-if="selected==0"
                            no-body
                            style="max-width: 600px; transform: scale(.9);"
                            class="border-0 mx-auto" >
                            <Table />
                        </b-card>

                        <b-card
                            v-if="selected==1"
                            no-body
                            style="max-width: 600px;"
                            class="border-0 mx-auto" >
                            <Chart />
                        </b-card>

                        <b-card
                            v-if="selected==2"
                            no-body
                            style="max-width: 600px; transform: scale(.9);"
                            class="border-0 mx-auto" >
                            <Calendar />
                        </b-card>

                        <b-card
                            v-if="selected==3"
                            no-body
                            class="border-0 mx-auto my-5 py-4" >
                            <Localization />
                        </b-card>
                    </div>
                </transition>
            </div>
        </div>
        
    </div>

</template>

<script>
import Calendar from '../playground/calendar'
import Table from '../playground/table'
import Chart from '../playground/chart'
import Localization from '../playground/localization'
export default {
    name: "Playground",
    components: {
        Calendar,
        Table,
        Chart,
        Localization,
    },
    data() {
        return{
            selected: null,
        }
    },
    mounted() {
        this.selected = 0;
    },
    methods: {
        changeSelected(id) {
            if(this.selected != id){
                this.selected = null;
                setTimeout(()=>{
                    this.selected = id;
                }, 10);
            }
        }
    }
}
</script>