<template>

    <div class="col-12 mx-auto my-md-0 px-0">
        
        <div class="section d-flex align-items-center justify-content-center flex-wrap bg-primary-gradient text-white  min-vh-100">
            <b-card
                bg-variant="transparent"
                style="max-width: 600px; min-width: 360px; "
                class="border-0 text-left mx-3 mx-md-2" >
                <b-card-title class="header-1 mb-4"> Custom Mobile <br> Application Development </b-card-title>
                <b-card-text class="my-4">
                    Let's turn your great ideas into amazing mobile solutions!
                </b-card-text>

                <b-button v-b-toggle.contact-sidebar variant="transparent" class="outline-light rounded-custom-5 my-auto font-weight-bold small px-3"> Contact Us </b-button>
            </b-card>

            <div class="mx-3 my-5 w-100" style="max-width: 35em;">
                <b-img src="@/assets/mobile.svg" />
            </div>
        </div>

        <div class="d-flex align-items-stretch justify-content-center flex-wrap py-5 bg-white">
            <p class="col-12 header-1 py-0 px-0">Our Services</p>

            <b-card
                style="font-weight: 300; max-width: 350px;"
                body-class="d-flex flex-column"
                bg-variant="transparent"
                class="border-0 text-left mx-3 my-2 p-2" >
                <b-card-title class="header-2 mb-3 d-flex flex-column align-items-center">
                    <i class="fa fa-apple text-primary mb-3" style="font-size: 2em;"></i> 
                    <span>iOS App</span> 
                </b-card-title>
                <b-card-text class="mb-4 flex-grow-1">
                    Gorgeous apps for the most innovative mobile OS on the market. 
                    iOS development for iPhone, iPad, Apple Watch, Apple TV
                </b-card-text>
            </b-card>

            <b-card
                style="font-weight: 300; max-width: 350px;"
                body-class="d-flex flex-column"
                bg-variant="transparent"
                class="border-0 text-left mx-3 my-2 p-2" >
                <b-card-title class="header-2 mb-3 d-flex flex-column align-items-center">
                    <i class="fa fa-android text-primary mb-3" style="font-size: 2em;"></i> 
                    <span>Android App</span> 
                </b-card-title>
                <b-card-text class="mb-4 flex-grow-1">
                    Delivering your ideas to the biggest mobile audience possible. 
                    Android development for android Phones, Tablets, Wearables, Google TV
                </b-card-text>
            </b-card>

            <b-card
                style="font-weight: 300; max-width: 350px;"
                body-class="d-flex flex-column"
                bg-variant="transparent"
                class="border-0 text-left mx-3 my-2 p-2" >
                <b-card-title class="header-2 mb-3 d-flex flex-column align-items-center">
                    <i class="fa fa-arrows-alt-h text-primary mb-3" style="font-size: 2em;"></i> 
                    <span>Cross-platform</span> 
                </b-card-title>
                <b-card-text class="mb-4 flex-grow-1">
                    Keeping up with tech trends, we use efficient cross-platform tools 
                    to deliver great mobile applications that meet all your needs.
                </b-card-text>
            </b-card>
        </div>

        <div class="section d-flex align-items-stretch justify-content-center flex-wrap bg-white pb-5 px-md-5">
            <p class="col-12 header-1 mt-5 py-0 px-5">Solutions we are really good at</p>

            <b-card
                style="font-weight: 300; max-width: 350px;"
                body-class="d-flex flex-column"
                class="border-0 shadow leaf-card text-left mx-3 my-4 p-2" >
                <b-card-title class="header-2 mb-3 d-flex align-items-center">
                    <i class="fa fa-cogs text-primary mr-3" style="font-size: 1.5em;"></i> 
                    <span>Business process automation </span> 
                </b-card-title>
                <b-card-text class="flex-grow-1">
                    With BPA app trigger and optimise company operations and activities: 
                    sales, marketing, service, management, HR, supply chain, finance, and more.
                </b-card-text>
            </b-card>

            <b-card
                style="font-weight: 300; max-width: 350px;"
                body-class="d-flex flex-column"
                class="border-0 shadow leaf-card text-left mx-3 my-4 p-2" >
                <b-card-title class="header-2 mb-3 d-flex align-items-center">
                    <i class="fa fa-share-alt text-primary mr-3" style="font-size: 1.5em;"></i> 
                    <span>Loyalty programs</span> 
                </b-card-title>
                <b-card-text class="flex-grow-1">
                    Efficient apps to enforce sales, build brand and customer loyalty, 
                    increase customer retention rate and ARPU.
                </b-card-text>
            </b-card>

            <b-card
                style="font-weight: 300; max-width: 350px;"
                body-class="d-flex flex-column"
                class="border-0 shadow leaf-card text-left mx-3 my-4 p-2" >
                <b-card-title class="header-2 mb-3 d-flex align-items-center">
                    <i class="fa fa-university text-primary mr-3" style="font-size: 1.5em;"></i> 
                    <span>edTech & eLearning</span> 
                </b-card-title>
                <b-card-text class="flex-grow-1">
                    Powerful e-learning solutions, LMS and M-Learning App for students or business, 
                    packed with interactive designs and great simplicity.
                </b-card-text>
            </b-card>

            <b-card
                style="font-weight: 300; max-width: 350px;"
                body-class="d-flex flex-column"
                class="border-0 shadow leaf-card text-left mx-3 my-4 p-2" >
                <b-card-title class="header-2 mb-3 d-flex align-items-center">
                    <i class="fa fa-photo-video text-primary mr-3" style="font-size: 1.5em;"></i> 
                    <span>Media & Entertainment</span> 
                </b-card-title>
                <b-card-text class="flex-grow-1">
                   Multifunctional and easy-to-use applications will engage your audience 
                   and meet all their requirements.
                </b-card-text>
            </b-card>

            <b-card
                style="font-weight: 300; max-width: 350px;"
                body-class="d-flex flex-column"
                class="border-0 shadow leaf-card text-left mx-3 my-4 p-2" >
                <b-card-title class="header-2 mb-3 d-flex align-items-center">
                    <i class="fa fa-project-diagram text-primary mr-3" style="font-size: 1.5em;"></i> 
                    <span>Social Networks</span> 
                </b-card-title>
                <b-card-text class="flex-grow-1">
                    Let's create an engaging social media application with high scalability 
                    and rich functionality that provides great user experience and usability.
                </b-card-text>
            </b-card>

        </div>

        <div class="d-flex align-items-stretch justify-content-center flex-wrap py-5">
            <p class="col-12 header-1 py-0 px-5">We help you create Apps for</p>

            <b-card
                style="font-weight: 300; max-width: 350px;"
                body-class="d-flex flex-column"
                bg-variant="transparent"
                class="border-0 text-left mx-3 my-2 p-2" >
                <b-card-title class="header-2 mb-3 d-flex flex-column align-items-center">
                    <i class="fa fa-city text-primary mb-3" style="font-size: 2em;"></i> 
                    <span>Enterprise</span> 
                </b-card-title>
                <b-card-text class="mb-4 flex-grow-1">
                    Our collaborative consulting on Mobile Strategy serves as a catalyst in streamlining 
                    IT frameworks and processes with the mobile roadmap that is aligned with enterprise scenarios, 
                    policies and current systems. 
                </b-card-text>
            </b-card>

            <b-card
                style="font-weight: 300; max-width: 350px;"
                body-class="d-flex flex-column"
                bg-variant="transparent"
                class="border-0 text-left mx-3 my-2 p-2" >
                <b-card-title class="header-2 mb-3 d-flex flex-column align-items-center">
                    <i class="far fa-building text-primary mb-3" style="font-size: 2em;"></i> 
                    <span>Business</span> 
                </b-card-title>
                <b-card-text class="mb-4 flex-grow-1">
                    We map business’s needs and use cases to enable them to quickly respond to the changes. 
                    We help your organization mobilize the inter-department communication, 
                    workflows and instant information availability. 
                </b-card-text>
            </b-card>

            <b-card
                style="font-weight: 300; max-width: 350px;"
                body-class="d-flex flex-column"
                bg-variant="transparent"
                class="border-0 text-left mx-3 my-2 p-2" >
                <b-card-title class="header-2 mb-3 d-flex flex-column align-items-center">
                    <i class="fa fa-users text-primary mb-3" style="font-size: 2em;"></i> 
                    <span>Consumers</span> 
                </b-card-title>
                <b-card-text class="mb-4 flex-grow-1">
                    Our mobile experts help brands, businesses and community organizations to build scalable 
                    mobile application solutions that are aimed to be used on a daily basis by global 
                    consumers, multiple times in a day.
                </b-card-text>
            </b-card>
        </div>

    </div>

</template>

<script>
export default {
    name: "Mobile",
    data() {
        return{
            
        }
    },
    mounted() {

    },
    methods: {
        
    }
}
</script>

<style scoped>
.section:first-child {
    padding-top: 5em !important;
}
@media screen and (min-width: 768px) {
    .section:first-child {
        padding-top: 0px !important;
    }
}
</style>