<template>

    <div class="mx-auto">

        <b-card 
            class="border-0 shadow text-muted"
            no-body
            header-bg-variant="white"
            header-class="text-left p-3 px-4 font-weight-bold border-0 d-flex align-items-center justify-content-between flex-wrap" >
            <template v-slot:header>                
                <span> Demo Report </span>
                <span>
                    <a href="/resources/demo.csv" class="py-1 px-3 mr-1 border text-muted font-weight-normal" download="demo.csv">CSV</a>
                    <a href="/resources/demo.pdf" class="py-1 px-3 mr-1 border text-muted font-weight-normal" download="demo.pdf">PDF</a>
                </span>
            </template>

            <b-table
                show-empty
                hover
                outlined
                striped
                borderless
                :items="reports"
                :busy="loadingReports"
                :fields="reportsHeaders"
                :per-page="5"
                :current-page="currentPage"
                head-variant="primary"
                class="m-0 text-left border-0">
                <template v-slot:table-busy>
                    <div class="text-center text-muted my-2">
                        <b-spinner class="align-middle"></b-spinner>
                    </div>    
                </template>

                <template v-slot:empty>
                    <h5 class="text-muted text-center py-4"> No records</h5>
                </template>

            </b-table>

            <div class="d-inline-flex align-items-center justify-content-between pt-4">
                <div class="d-inline-flex flex-column align-items-start small">
                    <span class="font-weight-bold d-flex align-items-center py-1 px-3"> Total count ፡ {{reports.length}} </span>
                    <span class="font-weight-bold d-flex align-items-center pb-3 px-3"> Total sales ፡ {{totalSales}} </span>
                </div>
                <b-pagination 
                    v-model="currentPage" 
                    :per-page="perPage" 
                    :total-rows="reports.length"
                    class="m-0" 
                    page-class="current-page"></b-pagination>
            </div>

        </b-card>

    </div>

</template>
<script>
/* eslint-disable */
export default {
    name: "Table",
    data() {
        return{
            loadingReports: false,
            currentPage: 1,
            perPage: 5,
            reportsHeaders: [
                { key: 'date', label: "Date" },
                { key: 'item', label: "Item" },
                { key: 'units', label: "Units" },
                { key: 'unitPrice', label: "Unit Price" },
                { key: 'totalSales', label: "Total Sales" },
            ],
            totalReportCount: 0,
            reports: [
                {date: "15/02/2021", item: "Pencil", units: 95, unitPrice: 1.99, totalSales: 189.05},
                {date: "15/02/2021", item: "Binder", units: 50, unitPrice: 19.99, totalSales: 999.50},
                {date: "15/02/2021", item: "Pen", units: 27, unitPrice: 19.99, totalSales: 539.73},
                {date: "15/02/2021", item: "Desk", units: 2, unitPrice: 125.00, totalSales: 250.00},
                {date: "16/02/2021", item: "Pen Set", units: 16, unitPrice: 15.99, totalSales: 255.84},
                {date: "16/02/2021", item: "Desk", units: 2, unitPrice: 125.00, totalSales: 250.00},
                {date: "16/02/2021", item: "Pen Set", units: 16, unitPrice: 15.99, totalSales: 255.84},
                {date: "16/02/2021", item: "Binder", units: 50, unitPrice: 19.99, totalSales: 999.50},
                {date: "17/02/2021", item: "Pen", units: 27, unitPrice: 19.99, totalSales: 539.73},
                {date: "17/02/2021", item: "Pencil", units: 95, unitPrice: 1.99, totalSales: 189.05},
                {date: "17/02/2021", item: "Pencil", units: 95, unitPrice: 1.99, totalSales: 189.05},
                {date: "18/02/2021", item: "Binder", units: 50, unitPrice: 19.99, totalSales: 999.50},
                {date: "18/02/2021", item: "Pen Set", units: 16, unitPrice: 15.99, totalSales: 255.84},
            ],
            totalSales: 5912.63,
        }
    },
    mounted() {
        
    },
    methods: {

    }
}
</script>

<style>
.thead-primary {
  background-color: #5874c9 !important;
  color: white;
}
.page-item.active .page-link {
  background-color: #5874c9 !important;
}
</style>