<template>

    <div class="col-12 mx-auto my-md-0 px-0">
        
        <div class="section d-flex align-items-center justify-content-center flex-wrap bg-primary-gradient text-white py-5 min-vh-100">
            <b-card
                bg-variant="transparent"
                style="max-width: 600px; min-width: 360px; "
                class="border-0 text-left mx-3 mx-md-2" >
                <b-card-title class="header-1 mb-4"> Custom Web <br> Application Development </b-card-title>
                <b-card-text class="my-4">
                    Let's turn your great ideas into amazing web solutions!
                </b-card-text>

                <b-button v-b-toggle.contact-sidebar variant="transparent" class="outline-light rounded-custom-5 my-auto font-weight-bold small px-3"> Contact Us </b-button>
            </b-card>

            <div class="mx-3 my-5 w-100" style="max-width: 35em;">
                <b-img src="@/assets/web.svg" />
            </div>
        </div>

        <div class="d-flex align-items-stretch justify-content-center flex-wrap py-5" style="margin-top: -6em !important;">

            <b-card
                style="font-weight: 300; max-width: 300px; min-width: 275px;"
                body-class="d-flex flex-column"
                bg-variant="info"
                class="border-0 shadow-sm col-md-3 leaf-card text-left mx-3 my-2 text-white rounded-custom-1" >
                <b-card-title class="header-2 mb-3 text-left">
                    <span>New Web Development</span> 
                </b-card-title>
                <b-card-text class="small">
                    Using up-to-date technologies and having great expertise in building 
                    web projects of any difficulty level, we deliver complex software 
                    solutions that meet your business specific requirements and goals.
                </b-card-text>
            </b-card>

            <b-card
                style="font-weight: 300; max-width: 300px; min-width: 275px;"
                body-class="d-flex flex-column"
                bg-variant="primary"
                class="border-0 shadow-sm col-md-3 leaf-card text-left mx-3 my-2 text-white rounded-custom-1" >
                <b-card-title class="header-2 mb-3 text-left">
                    <span>Technical Audit</span> 
                </b-card-title>
                <b-card-text class="small">
                    An independent and objective review of your current software solution 
                    through in-depth testing and expert analysis. We can uncover system 
                    vulnerability and find opportunities in process automation, validation, 
                    speed, and agility.
                </b-card-text>
            </b-card>

            <b-card
                style="font-weight: 300; max-width: 300px; min-width: 275px; background-color: #ef832b;"
                body-class="d-flex flex-column"
                class="border-0 shadow-sm col-md-3 leaf-card text-left mx-3 my-2 text-white rounded-custom-1" >
                <b-card-title class="header-2 mb-3 text-left">
                    <span>Web App Modernization</span> 
                </b-card-title>
                <b-card-text class="small">
                    Maybe it's time to upgrade your solution? We can help you to redesign, 
                    refactor and enhance the outdated systems. Also our experts are ready 
                    to upgrade your website to Progressive Web App (PWA).
                </b-card-text>
            </b-card>

            <b-card
                style="font-weight: 300; max-width: 300px; min-width: 275px;"
                body-class="d-flex flex-column"
                bg-variant="dark"
                class="border-0 shadow-sm col-md-3 leaf-card text-left mx-3 my-2 text-white rounded-custom-1" >
                <b-card-title class="header-2 mb-3 text-left">
                    <span>Project Rescue</span> 
                </b-card-title>
                <b-card-text class="small">
                    If your current web application development project has stalled or its 
                    quality doesn't satisfy your requirements – we can help you. Our 
                    experts are ready to cope with hardest technologies and get back your 
                    project on schedule!
                </b-card-text>
            </b-card>

        </div>

        <div class="section d-flex align-items-stretch justify-content-center flex-wrap bg-white pb-5 px-md-5">
            <p class="col-12 header-1 mt-5 py-0 px-5">Solutions we are really good at</p>

            <b-card
                style="font-weight: 300; max-width: 350px;"
                body-class="d-flex flex-column"
                class="border-0 shadow leaf-card text-left mx-3 my-4 p-2" >
                <b-card-title class="header-2 mb-3 d-flex align-items-center">
                    <i class="fa fa-cogs text-primary mr-3" style="font-size: 1.5em;"></i> 
                    <span>Business process automation </span> 
                </b-card-title>
                <b-card-text class="flex-grow-1">
                    With BPA app trigger and optimise company operations and activities: 
                    sales, marketing, service, management, HR, supply chain, finance, and more.
                </b-card-text>
            </b-card>

            <b-card
                style="font-weight: 300; max-width: 350px;"
                body-class="d-flex flex-column"
                class="border-0 shadow leaf-card text-left mx-3 my-4 p-2" >
                <b-card-title class="header-2 mb-3 d-flex align-items-center">
                    <i class="fa fa-share-alt text-primary mr-3" style="font-size: 1.5em;"></i> 
                    <span>Loyalty programs</span> 
                </b-card-title>
                <b-card-text class="flex-grow-1">
                    Efficient apps to enforce sales, build brand and customer loyalty, 
                    increase customer retention rate and ARPU.
                </b-card-text>
            </b-card>

            <b-card
                style="font-weight: 300; max-width: 350px;"
                body-class="d-flex flex-column"
                class="border-0 shadow leaf-card text-left mx-3 my-4 p-2" >
                <b-card-title class="header-2 mb-3 d-flex align-items-center">
                    <i class="fa fa-university text-primary mr-3" style="font-size: 1.5em;"></i> 
                    <span>edTech & eLearning</span> 
                </b-card-title>
                <b-card-text class="flex-grow-1">
                    Powerful e-learning solutions, LMS and M-Learning App for students or business, 
                    packed with interactive designs and great simplicity.
                </b-card-text>
            </b-card>

            <b-card
                style="font-weight: 300; max-width: 350px;"
                body-class="d-flex flex-column"
                class="border-0 shadow leaf-card text-left mx-3 my-4 p-2" >
                <b-card-title class="header-2 mb-3 d-flex align-items-center">
                    <i class="fa fa-photo-video text-primary mr-3" style="font-size: 1.5em;"></i> 
                    <span>Media & Entertainment</span> 
                </b-card-title>
                <b-card-text class="flex-grow-1">
                   Multifunctional and easy-to-use applications will engage your audience 
                   and meet all their requirements.
                </b-card-text>
            </b-card>

            <b-card
                style="font-weight: 300; max-width: 350px;"
                body-class="d-flex flex-column"
                class="border-0 shadow leaf-card text-left mx-3 my-4 p-2" >
                <b-card-title class="header-2 mb-3 d-flex align-items-center">
                    <i class="fa fa-project-diagram text-primary mr-3" style="font-size: 1.5em;"></i> 
                    <span>Social Networks</span> 
                </b-card-title>
                <b-card-text class="flex-grow-1">
                    Let's create an engaging social media application with high scalability 
                    and rich functionality that provides great user experience and usability.
                </b-card-text>
            </b-card>

        </div>

        <div class="section d-flex align-items-stretch justify-content-center flex-wrap py-5 px-md-5 mx-md-5">
            <p class="col-12 header-1  p-0">Why Us</p>

            <b-card
                bg-variant="transparent"
                style="font-weight: 300; max-width: 300px;"
                body-class="d-flex flex-column"
                class="border-0 text-left" >
                <b-card-title class="header-2 my-3 d-flex align-items-center">
                    <i class="fab fa-searchengin text-primary mr-3" style="font-size: 2em;"></i>
                    Care about SEO
                </b-card-title>
                <b-card-text class="mb-4 flex-grow-1">
                    We use cutting edge ways such as Server Side Rendering, JAMstack, 
                    Hybrid Apps to provide your site with opportunity of 
                    vast amounts of organic search.
                </b-card-text>
            </b-card>

            <b-card
                bg-variant="transparent"
                style="font-weight: 300; max-width: 300px;"
                body-class="d-flex flex-column"
                class="border-0 text-left" >
                <b-card-title class="header-2 my-3 d-flex align-items-center">
                    <i class="fab fa-rev text-primary mr-3" style="font-size: 2em;"></i>
                    Code Reusability
                </b-card-title>
                <b-card-text class="mb-4 flex-grow-1">
                    We use component-based approach and atomic design system to provide 
                    you with reusable code which can be used in your future projects. 
                    So development cost will be lower.
                </b-card-text>
            </b-card>

            <b-card
                bg-variant="transparent"
                style="font-weight: 300; max-width: 300px;"
                body-class="d-flex flex-column"
                class="border-0 text-left" >
                <b-card-title class="header-2 my-3 d-flex align-items-center">
                    <i class="fa fa-lock text-primary mr-3" style="font-size: 2em;"></i>
                    Security
                </b-card-title>
                <b-card-text class="mb-4 flex-grow-1">
                    Security audit is inseparable part of our development process. 
                    We use only proven technologies safety of which leaves no doubt.
                </b-card-text>
            </b-card>

            <b-card
                bg-variant="transparent"
                style="font-weight: 300; max-width: 300px;"
                body-class="d-flex flex-column"
                class="border-0 text-left" >
                <b-card-title class="header-2 my-3 d-flex align-items-center">
                    <i class="fa fa-bolt text-primary mr-3" style="font-size: 2em;"></i>
                    Speed
                </b-card-title>
                <b-card-text class="mb-4 flex-grow-1">
                    Using caching and CDN, we can guarantee - Speed Index of your website 
                    will be more than 90%. Good advantage over competitors' sites, right?
                </b-card-text>
            </b-card>

            <b-card
                bg-variant="transparent"
                style="font-weight: 300; max-width: 300px;"
                body-class="d-flex flex-column"
                class="border-0 text-left" >
                <b-card-title class="header-2 my-3 d-flex align-items-center">
                    <i class="fa fa-grip-vertical text-primary mr-3" style="font-size: 2em;"></i>
                    Adaptive design
                </b-card-title>
                <b-card-text class="mb-4 flex-grow-1">
                    No matter what device or browser your user has. You can be sure that 
                    he’ll get the best user experience.
                </b-card-text>
            </b-card>

            <b-card
                bg-variant="transparent"
                style="font-weight: 300; max-width: 300px;"
                body-class="d-flex flex-column"
                class="border-0 text-left" >
                <b-card-title class="header-2 my-3 d-flex align-items-center">
                    <i class="fa fa-file-signature text-primary mr-3" style="font-size: 2em;"></i>
                    Transparency
                </b-card-title>
                <b-card-text class="mb-4 flex-grow-1">
                    Our work is 100% transparent. You get full access to our reports 
                    and the project's progress at least once a week.
                </b-card-text>
            </b-card>

            <b-card
                bg-variant="transparent"
                style="font-weight: 300; max-width: 300px;"
                body-class="d-flex flex-column"
                class="border-0 text-left" >
                <b-card-title class="header-2 my-3 d-flex align-items-center">
                    <i class="fa fa-laugh-wink text-primary mr-3" style="font-size: 2em;"></i>
                    Can-do Attitude 
                </b-card-title>
                <b-card-text class="mb-4 flex-grow-1">
                    A tight deadline? Really demanding technical issues? Ready to 
                    handle it. And we’ll deliver what we've promised.
                </b-card-text>
            </b-card>

            <b-card
                bg-variant="transparent"
                style="font-weight: 300; max-width: 300px;"
                body-class="d-flex flex-column"
                class="border-0 text-left" >
                <b-card-title class="header-2 my-3 d-flex align-items-center">
                    <i class="fa fa-code text-primary mr-3" style="font-size: 2em;"></i>
                    High quality code
                </b-card-title>
                <b-card-text class="mb-4 flex-grow-1">
                    To make sure of this, we follow the best practices, including 
                    code review throughout the whole development process.
                </b-card-text>
            </b-card>

        </div>

    </div>

</template>

<script>
export default {
    name: "Web",
    data() {
        return{
            
        }
    },
    mounted() {

    },
    methods: {
        
    }
}
</script>

<style scoped>
.section:first-child {
    padding-top: 5em !important;
}
@media screen and (min-width: 768px) {
    .section:first-child {
        padding-top: 0px !important;
    }
}
</style>