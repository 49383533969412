<template>

    <div class="col-12 mx-auto my-md-0 px-0">
        
        <div class="section d-flex align-items-center justify-content-center flex-wrap py-5 bg-primary-gradient text-white min-vh-100">
            <b-card
                bg-variant="transparent"
                style="max-width: 500px; min-width: 360px; "
                class="border-0 text-left mx-auto mx-md-3" >
                <b-card-title class="header-1 mb-4"> Maintenance cost reduction </b-card-title>
                <b-card-text class="mb-4">
                    Our approach to software support is based on the belief that proper maintenance 
                    and improvements along the way are key for keeping the support costs as low as 
                    possible - failing to react to a certain problem quickly will result 
                    in very high overheads.
                </b-card-text>
            </b-card>

            <div class="mx-3 my-5 w-100" style="max-width: 35em;">
                <b-img src="@/assets/maintenance.svg" class="col-12" />
            </div>
        </div>
        
        <div class="d-flex align-items-center justify-content-center flex-wrap pt-5">
            <b-card
                bg-variant="transparent"
                style="max-width: 450px; min-width: 360px; "
                class="border-0 text-left mx-auto mx-md-4" >
                <span>step 01</span>
                <b-card-title class="h1 font-weight-bold mb-4"> Audit and Consulting </b-card-title>
                <b-card-text class="small">
                    Independent audit of software products to uncover system problems and 
                    opportunities of cost reduction. As a result, you get a document that 
                    contains a detailed product status assessment including safety and 
                    security as well as recommendations on their enhancement. 
                </b-card-text>
            </b-card>
            <div class="mx-3 mx-md-3 my-5" style="max-width: 400px;">
                <b-img src="@/assets/undraw_10.svg" class="col-12" />
            </div>
        </div>
        
        <div class="d-flex align-items-center justify-content-center flex-wrap pb-5">
            <div class="mx-3 mx-md-3 my-5" style="max-width: 400px;">
                <b-img src="@/assets/undraw_11.svg" class="col-12" />
            </div>
            <b-card
                bg-variant="transparent"
                style="max-width: 450px; min-width: 360px; "
                class="border-0 text-left mx-auto mx-md-4" >
                <span>step 02</span>
                <b-card-title class="h1 font-weight-bold mb-4"> Maintenance </b-card-title>
                <b-card-text class="small">
                    Our profound expertise and competitve rates will enable you to reduce 
                    your system support costs while we always stay in touch - as if we 
                    were your in-house team.  
                </b-card-text>
            </b-card>
        </div>

        <div class="section d-flex flex-column align-items-center justify-content-center flex-wrap py-5 bg-white">
            <p class="header-1 py-0 px-0 mb-5">Maintenance services</p>
            
            <div class="d-flex align-items-center justify-content-center flex-wrap">

                <b-list-group class="mx-auto mx-md-5">
                    <b-list-group-item @click="selected=0" :active="selected==0" :class="{'bg-primary': selected==0}" class="btn border-0 d-flex flex-row align-items-center my-1" style="border-radius: 1em">
                        <div class="d-flex align-items-center mr-3 bg-white" style="width:4em; height:4em; border-radius:50%;">
                            <i class="fa fa-headset w-100" :class="{'text-primary': selected==0}" style="font-size: 2.5em;"></i>
                        </div>
                        <span class="h5">SLA Support</span>
                    </b-list-group-item>
                    
                    <b-list-group-item @click="selected=1" :active="selected==1" :class="{'bg-primary': selected==1}" class="btn border-0 d-flex flex-row align-items-center my-1" style="border-radius: 1em">
                        <div class="d-flex align-items-center mr-3 bg-white" style="width:4em; height:4em; border-radius:50%;">
                            <i class="fa fa-desktop w-100" :class="{'text-primary': selected==1}" style="font-size: 2.5em;"></i>
                        </div>
                        <span class="h5">System Monitoring</span>
                    </b-list-group-item>
                    
                    <b-list-group-item @click="selected=2" :active="selected==2" :class="{'bg-primary': selected==2}" class="btn border-0 d-flex flex-row align-items-center my-1" style="border-radius: 1em">
                        <div class="d-flex align-items-center mr-3 bg-white" style="width:4em; height:4em; border-radius:50%;">
                            <i class="fa fa-wrench w-100" :class="{'text-primary': selected==2}" style="font-size: 2.5em;"></i>
                        </div>
                        <span class="h5">App Modernization</span>
                    </b-list-group-item>
                    
                    <b-list-group-item @click="selected=3" :active="selected==3" :class="{'bg-primary': selected==3}" class="btn border-0 d-flex flex-row align-items-center my-1" style="border-radius: 1em">
                        <div class="d-flex align-items-center mr-3 bg-white" style="width:4em; height:4em; border-radius:50%;">
                            <i class="fa fa-superpowers w-100" :class="{'text-primary': selected==3}" style="font-size: 2.5em;"></i>
                        </div>
                        <span class="h5">Functionality Upgrades</span>
                    </b-list-group-item>
                    
                </b-list-group>

                <b-card
                    v-if="selected==0"
                    bg-variant="transparent"
                    style="max-width: 600px; min-width: 360px; "
                    class="border-0 shadow leaf-card text-left mx-auto mx-md-5" >
                    <div class="d-flex mb-3">
                        <div class="mx-3">
                            <div class="small py-3 border-bottom border-dark">
                                Derash provides support services for all systems, no matter the functionality or 
                                the vendor. We can offer a 3-level support system that enables us to tackle any 
                                issue from a simple bug fix to a new feature implementation.
                            </div>
                        </div>
                    </div>
                    <div class="d-flex mb-3">
                        <span class="h1 px-3 font-weight-bold text-muted"> 01 </span>
                        <div class="mx-3">
                            <div class="header-2">L1 Support</div>
                            <div class="small py-3 border-bottom border-dark">
                                User guidance, standard issue fixing, monitor system data analysis, etc.
                            </div>
                        </div>
                    </div>
                    <div class="d-flex mb-3">
                        <span class="h1 px-3 font-weight-bold text-muted"> 02 </span>
                        <div class="mx-3">
                            <div class="header-2">L2 Support</div>
                            <div class="small py-3 border-bottom border-dark">
                                Outsourced server administration, IT environment support (issue detection 
                                and fixing), proactive monitoring systems setup, etc.
                            </div>
                        </div>
                    </div>
                    <div class="d-flex mb-3">
                        <span class="h1 px-3 font-weight-bold text-muted"> 03 </span>
                        <div class="mx-3">
                            <div class="header-2">L3 Support</div>
                            <div class="small py-3">
                                Source code issue resolution, application code optimization, new functionality realization, etc.
                            </div>
                        </div>
                    </div>
                </b-card>

                <b-card
                    v-if="selected==1"
                    bg-variant="transparent"
                    style="max-width: 600px; min-width: 360px; "
                    class="border-0 shadow leaf-card text-left mx-auto mx-md-5" >
                    <div class="d-flex mb-3">
                        <div class="mx-3">
                            <div class="small py-3 border-bottom border-dark">
                                Our team makes sure that you save time and effort leaving all the IT 
                                infrastructure issues to us. We take over 3rd party applications 
                                and take care of the maintenance while you get to focus on your 
                                core business activities.
                            </div>
                        </div>
                    </div>
                    <div class="d-flex mb-3">
                        <span class="h1 px-3 font-weight-bold text-muted"> 01 </span>
                        <div class="mx-3">
                            <div class="header-2">Project Health Checkup</div>
                            <div class="small py-3 border-bottom border-dark">
                                Security audit, server management, backup storage setup, OS versions 
                                update, resources availability and performance control.
                            </div>
                        </div>
                    </div>
                    <div class="d-flex mb-3">
                        <span class="h1 px-3 font-weight-bold text-muted"> 02 </span>
                        <div class="mx-3">
                            <div class="header-2">Launch</div>
                            <div class="small py-3 border-bottom border-dark">
                                Pre-release environment setup, system monitoring, scalability automation.
                            </div>
                        </div>
                    </div>
                    <div class="d-flex mb-3">
                        <span class="h1 px-3 font-weight-bold text-muted"> 03 </span>
                        <div class="mx-3">
                            <div class="header-2">New version release</div>
                            <div class="small py-3 border-bottom border-dark">
                                
                            </div>
                        </div>
                    </div>
                    <div class="d-flex mb-3">
                        <span class="h1 px-3 font-weight-bold text-muted"> 04 </span>
                        <div class="mx-3">
                            <div class="header-2">Hot-fixes</div>
                            <div class="small py-3">
                                Fast reaction to issues, system restoration.
                            </div>
                        </div>
                    </div>
                </b-card>

                <b-card
                    v-if="selected==2"
                    bg-variant="transparent"
                    style="max-width: 600px; min-width: 360px; "
                    class="border-0 shadow leaf-card text-left mx-auto mx-md-5" >
                    <div class="d-flex mb-3">
                        <div class="mx-3">
                            <div class="small py-3 border-bottom border-dark">
                                Along with maintenance our team provides application modernization services 
                                that improve overall product quality and lower maintenance costs even more. 
                                In a nutshell, our experts transform software into something more effective and agile.
                            </div>
                        </div>
                    </div>
                    <div class="d-flex mb-3">
                        <span class="h1 px-3 font-weight-bold text-muted"> 01 </span>
                        <div class="mx-3">
                            <div class="header-2">Cloud Solutions Delivery</div>
                            <div class="small py-3 border-bottom border-dark">
                                Infrastructure migration, database migration, post-migration consulting.
                            </div>
                        </div>
                    </div>
                    <div class="d-flex mb-3">
                        <span class="h1 px-3 font-weight-bold text-muted"> 02 </span>
                        <div class="mx-3">
                            <div class="header-2">Re-platform</div>
                            <div class="small py-3 border-bottom border-dark">
                                Server-side rendering to SPA, monolith architecture to microservices, cross-platform to native.
                            </div>
                        </div>
                    </div>
                    <div class="d-flex mb-3">
                        <span class="h1 px-3 font-weight-bold text-muted"> 03 </span>
                        <div class="mx-3">
                            <div class="header-2">UI/UX modernization</div>
                            <div class="small py-3 border-bottom border-dark">
                                Effective audit, creation of UX-strategy, redesign of current solution. 
                            </div>
                        </div>
                    </div>
                    <div class="d-flex mb-3">
                        <span class="h1 px-3 font-weight-bold text-muted"> 04 </span>
                        <div class="mx-3">
                            <div class="header-2">Code refactoring</div>
                            <div class="small py-3">
                                Migration from outdated languages to reduce maintenance costs.
                            </div>
                        </div>
                    </div>
                </b-card>

                <b-card
                    v-if="selected==3"
                    bg-variant="transparent"
                    style="max-width: 600px; min-width: 360px; "
                    class="border-0 shadow leaf-card text-left mx-auto mx-md-5" >
                    <div class="d-flex mb-3">
                        <div class="mx-3">
                            <div class="small py-3 border-bottom border-dark">
                                Derash aims to help improve the existing product to keep it up with 
                                business and market needs. The company provides a wide range of maintenance 
                                activities: from making small tweaks to new feature or flow implementation.
                            </div>
                        </div>
                    </div>
                    <div class="d-flex mb-3">
                        <span class="h1 px-3 font-weight-bold text-muted"> 01 </span>
                        <div class="mx-3">
                            <div class="header-2">Integration</div>
                            <div class="small py-3 border-bottom border-dark">
                                Integration of your platform with other systems or tools.
                            </div>
                        </div>
                    </div>
                    <div class="d-flex mb-3">
                        <span class="h1 px-3 font-weight-bold text-muted"> 02 </span>
                        <div class="mx-3">
                            <div class="header-2">Features</div>
                            <div class="small py-3 border-bottom border-dark">
                                Adding new features to application.
                            </div>
                        </div>
                    </div>
                    <div class="d-flex mb-3">
                        <span class="h1 px-3 font-weight-bold text-muted"> 03 </span>
                        <div class="mx-3">
                            <div class="header-2">Audits</div>
                            <div class="small py-3 border-bottom border-dark">
                                Security audit and OS updates.
                            </div>
                        </div>
                    </div>
                </b-card>
                
            </div>
        </div>

        <div class="section d-flex align-items-center justify-content-center flex-wrap py-5">
            <b-card
                bg-variant="transparent"
                style="max-width: 450px; min-width: 360px; "
                class="border-0 text-left mx-auto mx-md-2" >
                <b-card-title class="header-1 mb-4"> What you get </b-card-title>
                <b-card-text class="mb-4">
                    Provided that a correct approach to software maintenance is applied, 
                    you can be sure that your project will serve you for years to come 
                    and will never lose its relevance.
                </b-card-text>
            </b-card>

            <div class="mx-auto mx-md-2 my-5 d-flex align-items-center justify-content-center flex-wrap" style="min-width: 360px;">
                
                <div class="d-flex flex-column">
                    <b-card
                        style="font-weight: 300; max-width: 350px;"
                        body-class="d-flex flex-column"
                        class="border-0 shadow leaf-card text-left my-4 mx-md-3" >
                        <b-card-title class="header-2 mb-3 d-flex align-items-center">
                            <div class="d-flex align-items-center mr-3 bg-info text-center" style="width:2.5em; height:2.5em; border-radius:50%;">
                                <i class="fa fa-donate w-100 text-white" style="font-size: 1.5em;"></i>
                            </div>
                            <span>Budget Flexibility</span> 
                        </b-card-title>
                        <div class="small">
                            We tailor the scope of work to your budget, so you get predictability and transparancy.
                            Even if you need only one part-time specialist, we could provide you with this.
                        </div>
                    </b-card>
                    
                    <b-card
                        style="font-weight: 300; max-width: 350px;"
                        body-class="d-flex flex-column"
                        class="border-0 shadow leaf-card text-left my-4 mx-md-3" >
                        <b-card-title class="header-2 mb-3 d-flex align-items-center">
                            <div class="d-flex align-items-center mr-3 bg-dark text-center" style="width:2.5em; height:2.5em; border-radius:50%;">
                                <i class="fa fa-sort-amount-down w-100 text-white" style="font-size: 1.25em;"></i>
                            </div>
                            <span>Cost Reduction</span> 
                        </b-card-title>
                        <div class="small">
                            We are cheaper than full-time in-house team. With a remote team you won’t have to worry 
                            about taxation, perks for employees, internal issues, etc. - you only pay for the actual work done.
                        </div>
                    </b-card>
                </div>

                <div class="d-flex flex-column">
                    <b-card
                        style="font-weight: 300; max-width: 350px;"
                        body-class="d-flex flex-column"
                        class="border-0 shadow leaf-card text-left my-4 mx-md-3" >
                        <b-card-title class="header-2 mb-3 d-flex align-items-center">
                            <div class="d-flex align-items-center mr-3 bg-danger text-center" style="width:2.5em; height:2.5em; border-radius:50%;">
                                <i class="fa fa-phone-alt w-100 text-white" style="font-size: 1.5em;"></i>
                            </div>
                            <span>Prompt Response</span> 
                        </b-card-title>
                        <div class="small">
                            We make sure that we react to your issues at once - our average response time 
                            is under 1 hour and it doesn’t take us more than 24 hours to fix a problem.
                        </div>
                    </b-card>

                    <b-card
                        style="font-weight: 300; max-width: 350px;"
                        body-class="d-flex flex-column"
                        class="border-0 shadow leaf-card text-left my-4 mx-md-3" >
                        <b-card-title class="header-2 mb-3 d-flex align-items-center">
                            <div class="d-flex align-items-center mr-3 bg-success text-center p-0" style="width:2.5em; height:2.5em; border-radius:50%;">
                                <i class="fa fa-briefcase w-100 text-white" style="font-size: 1.25em;"></i>
                            </div>
                            <span>Mature Business</span> 
                        </b-card-title>
                        <div class="small">
                            We’re proactive in our reactions. We’ve established an 
                            efficient way of operating - design, development, reporting, bug fixing are all seamless. 
                        </div>
                    </b-card>
                </div>
                
            </div>
        </div>

    </div>

</template>

<script>
export default {
    name: "Maintenance",
    data() {
        return{
            selected: 0,            
        }
    },
    mounted() {

    },
    methods: {
        
    }
}
</script>

<style>

</style>