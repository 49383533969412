<template>

    <div class="col-12 mx-auto my-md-0 px-0">
        
        <div class="section d-flex align-items-center justify-content-center flex-wrap py-5">
            <b-card
                bg-variant="transparent"
                style="max-width: 500px; min-width: 360px; "
                class="border-0 text-left mx-auto mx-md-2" >
                <b-card-title class="header-1 mb-4"> App modernization </b-card-title>
                <b-card-text class="mb-4">
                    We refactor and repurpose legacy software in order to make it correspond to 
                    business and market needs via modern technology solutions, improving the 
                    source code’s quality and reengineering the core business-processes.
                </b-card-text>
            </b-card>

            <div class="mx-auto mx-md-2 my-2" style="max-width: 500px;">
                <b-img src="@/assets/undraw_6.svg" class="col-12" />
            </div>
        </div>
        
        <div class="section d-flex align-items-center justify-content-center flex-wrap py-5">
            <b-card
                bg-variant="transparent"
                style="max-width: 450px; min-width: 360px; "
                class="border-0 text-left mx-auto mx-md-2" >
                <b-card-title class="header-1 mb-4"> Modernization services </b-card-title>
                <b-card-text class="mb-4">
                    Our team provides application modernization services that improve the overall quality and 
                    lower the costs. In other words, our legacy modernization experts transform your legacy 
                    software into something more effective and agile.
                </b-card-text>
            </b-card>

            <div class="mx-auto mx-md-2 my-5 d-flex align-items-center justify-content-center flex-wrap" style="min-width: 360px;">
                
                <b-card
                    style="font-weight: 300; max-width: 350px;"
                    body-class="d-flex flex-column"
                    class="border-0 shadow leaf-card text-left my-4 mx-md-3" >
                    <b-card-title class="header-2 mb-3 d-flex align-items-center">
                        <div class="d-flex align-items-center mr-3 bg-danger text-center" style="width:2.5em; height:2.5em; border-radius:50%;">
                            <i class="fab fa-rev w-100 text-white" style="font-size: 1.5em;"></i>
                        </div>
                        <span>Re-platform</span> 
                    </b-card-title>
                    <ul class="small">
                        <li>Server-side rendering to SPA</li>
                        <li>CMS to JAMstack</li>
                        <li>Monolith Architecture to Microservices</li>
                        <li>Cross-platform to Native</li>
                    </ul>
                </b-card>

                <div class="d-flex flex-column">
                    <b-card
                        style="font-weight: 300; max-width: 350px;"
                        body-class="d-flex flex-column"
                        class="border-0 shadow leaf-card text-left my-4 mx-md-3" >
                        <b-card-title class="header-2 mb-3 d-flex align-items-center">
                            <div class="d-flex align-items-center mr-3 bg-info text-center" style="width:2.5em; height:2.5em; border-radius:50%;">
                                <i class="fa fa-palette w-100 text-white" style="font-size: 1.25em;"></i>
                            </div>
                            <span>UI/UX modernization</span> 
                        </b-card-title>
                        <ul class="small">
                            <li>Effective audit</li>
                            <li>Creation of UX-strategy</li>
                            <li>Redesign of current solution</li>
                        </ul>
                    </b-card>

                    <b-card
                        style="font-weight: 300; max-width: 350px;"
                        body-class="d-flex flex-column"
                        class="border-0 shadow leaf-card text-left my-4 mx-md-3" >
                        <b-card-title class="header-2 mb-3 d-flex align-items-center">
                            <div class="d-flex align-items-center mr-3 bg-success text-center" style="width:2.5em; height:2.5em; border-radius:50%;">
                                <i class="fa fa-cloud w-100 text-white" style="font-size: 1.25em;"></i>
                            </div>
                            <span>Migration to the Cloud</span> 
                        </b-card-title>
                        <ul class="small">
                            <li>Infrastructure migration</li>
                            <li>Database migration</li>
                            <li>Post-migration consulting</li>
                            <li>Optimization and support</li>
                        </ul>
                    </b-card>
                </div>
                
            </div>
        </div>

        <div class="section d-flex align-items-stretch justify-content-center flex-wrap bg-white py-5 px-md-5">
            <p class="col-12 header-1 my-4 px-4">The benefits of legacy software modernization</p>

            <div class="d-flex justify-content-center flex-wrap mx-md-5">

                <div class="d-flex align-items-center align-self-center m-3">
                    <div id="leaf-1" class="leaf-card bg-light d-inline-block shadow-sm p-3" style="width: 75px; height: 65px;">
                        <i class="fa fa-wrench text-primary" style="font-size: 2em;"></i>
                    </div>
                    <label for="leaf-1" class="p-3 text-left" style="width: 250px"> Maintainability </label>
                </div>

                <div class="d-flex align-items-center align-self-center m-3">
                    <div id="leaf-1" class="leaf-card bg-light d-inline-block shadow-sm p-3" style="width: 75px; height: 65px;">
                        <i class="fa fa-sort-amount-down text-primary" style="font-size: 2em;"></i>
                    </div>
                    <label for="leaf-1" class="p-3 text-left" style="width: 250px"> Reduced cost of new feature implementation </label>
                </div>

                <div class="d-flex align-items-center align-self-center m-3">
                    <div id="leaf-1" class="leaf-card bg-light d-inline-block shadow-sm p-3" style="width: 75px; height: 65px;">
                        <i class="fa fa-tachometer-alt text-primary" style="font-size: 2em;"></i>
                    </div>
                    <label for="leaf-1" class="p-3 text-left" style="width: 250px"> Increased system speed </label>
                </div>

                <div class="d-flex align-items-center align-self-center m-3">
                    <div id="leaf-1" class="leaf-card bg-light d-inline-block shadow-sm p-3" style="width: 75px; height: 65px;">
                        <i class="fa fa-palette text-primary" style="font-size: 2em;"></i>
                    </div>
                    <label for="leaf-1" class="p-3 text-left" style="width: 250px"> Modern competitive UI/UX </label>
                </div>

                <div class="d-flex align-items-center align-self-center m-3">
                    <div id="leaf-1" class="leaf-card bg-light d-inline-block shadow-sm p-3" style="width: 75px; height: 65px;">
                        <i class="fa fa-expand-alt text-primary" style="font-size: 2em;"></i>
                    </div>
                    <label for="leaf-1" class="p-3 text-left" style="width: 250px"> Easy to scale and integrate with other enterprise tools </label>
                </div>

                <div class="d-flex align-items-center align-self-center m-3">
                    <div id="leaf-1" class="leaf-card bg-light d-inline-block shadow-sm p-3" style="width: 75px; height: 65px;">
                        <i class="fa fa-shield-alt text-primary" style="font-size: 2em;"></i>
                    </div>
                    <label for="leaf-1" class="p-3 text-left" style="width: 250px"> Increased security level </label>
                </div>

                <div class="d-flex align-items-center align-self-center m-3">
                    <div id="leaf-1" class="leaf-card bg-light d-inline-block shadow-sm p-3" style="width: 75px; height: 65px;">
                        <i class="fa fa-user-check text-primary" style="font-size: 2em;"></i>
                    </div>
                    <label for="leaf-1" class="p-3 text-left" style="width: 250px"> Improved user engagement </label>
                </div>

            </div>

        </div>

    </div>

</template>

<script>
export default {
    name: "Modernization",
    data() {
        return{
            selected: 0,            
        }
    },
    mounted() {

    },
    methods: {
        
    }
}
</script>

<style>

</style>