<template>

    <canvas id="myChart" class="my-3" style="width: 20em; height: 15em;"></canvas>

</template>
<script>
/* eslint-disable */
import Chart from 'chart.js'
export default {
    name: "Chart",
    data() {
        return{
            
        }
    },
    mounted() {
        var ctx = document.getElementById('myChart').getContext('2d');
            var myChart = new Chart(ctx, {
            type: 'line',
            backgroundColor: 'transparent',
            data: {
                labels: ['January', 'February', 'March', 'April', 'May', 'June'],
                datasets: [
                    {
                        label: '2020 Sales',
                        data: [12, 19, 3, 5, 2, 3],
                        backgroundColor: "transparent",
                        borderColor: "#5874c9",
                        borderWidth: 3,
                    },
                    {
                        label: '2021 Sales',
                        data: [8, 15, 9, 15, 18, 14],
                        backgroundColor: "transparent",
                        borderColor: "#8874c9",
                        borderWidth: 3,
                    },
                    {
                        label: 'Activity bars',
                        type: "bar",
                        data: [10, 17, 6, 10, 10, 8],
                        borderWidth: 0,
                        backgroundColor: [
                            'rgba(255, 99, 132, 0.2)',
                            'rgba(54, 162, 235, 0.2)',
                            'rgba(255, 206, 86, 0.2)',
                            'rgba(75, 192, 192, 0.2)',
                            'rgba(153, 102, 255, 0.2)',
                            'rgba(255, 159, 64, 0.2)'
                        ],
                    },
                ]
            },
            options: {
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true
                        }
                    }]
                }
            }
        });
    },
    methods: {

    }
}
</script>

<style>

</style>