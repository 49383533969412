<template>

    <div class="col-12 mx-auto my-md-0 px-0 py-5">
        
        <b-card
            bg-variant="transparent"
            class="border-0 text-left mx-auto mx-md-3 text-muted" >
            <div class="mx-auto w-75">
                <h1 class="header-1 text-center mb-4">Our featured works</h1>

                <b-list-group class="mx-3 mx-md-5 d-flex flex-row  justify-content-center flex-wrap">
                    <b-list-group-item @click="changeSelected(0)" :active="selected==0" :class="{'bg-primary': selected==0}" class="btn border-0 d-flex flex-row align-items-center my-1 mx-2" style="border-radius: 1em">
                        <div class="d-flex align-items-center mr-lg-3 bg-white" style="width:4em; height:4em; border-radius:50%;">
                            <i class="fa fa-desktop w-100" :class="{'text-primary': selected==0, 'text-muted': selected!=0}" style="font-size: 2.5em;"></i>
                        </div>
                        <span class="h5 d-none d-lg-block" :class="{'text-muted': selected!=0}"> Web </span>
                    </b-list-group-item>
                    
                    <b-list-group-item @click="changeSelected(1)" :active="selected==1" :class="{'bg-primary': selected==1}" class="disabled btn border-0 d-flex flex-row align-items-center my-1 mx-2" style="border-radius: 1em">
                        <div class="d-flex align-items-center mr-lg-3 bg-white" style="width:4em; height:4em; border-radius:50%;">
                            <i class="fa fa-mobile w-100" :class="{'text-primary': selected==1, 'text-muted': selected!=1}" style="font-size: 2.5em;"></i>
                        </div>
                        <span class="h5 d-none d-lg-block" :class="{'text-muted': selected!=1}">Mobile</span>
                    </b-list-group-item>
                    
                    <b-list-group-item @click="changeSelected(2)" :active="selected==2" :class="{'bg-primary': selected==2}" class="disabled btn border-0 d-flex flex-row align-items-center my-1 mx-2" style="border-radius: 1em">
                        <div class="d-flex align-items-center mr-lg-3 bg-white" style="width:4em; height:4em; border-radius:50%;">
                            <i class="fa fa-bezier-curve w-100" :class="{'text-primary': selected==2, 'text-muted': selected!=2}" style="font-size: 2.5em;"></i>
                        </div>
                        <span class="h5 d-none d-lg-block" :class="{'text-muted': selected!=2}">UI/UX</span>
                    </b-list-group-item>
                </b-list-group>
            </div>

            <div class="d-flex align-items-start justify-content-center flex-wrap mt-5 mx-md-5 px-md-5">
                <b-card
                    v-if="selected==0 || selected==null"
                    overlay
                    v-b-modal.project
                    @click="selectedProject=0"
                    bg-variant="light"
                    class="border-0 shadow leaf-card-2 text-left my-4 mx-auto mx-md-3 overflow-hidden"
                    img-src="@/assets/portfolio/mengede/back.svg"
                    img-alt="Mengede"
                    style="width: 300px; height: 200px;"
                    text-variant="white" >
                    <b-card-title class="text-right header-2"> Mengede </b-card-title>
                    <b-card-text class="h4 text-light text-right mt-4">
                        Business Process Automation
                    </b-card-text>
                </b-card>
                
            </div>
        </b-card>

        <b-modal
            v-model="modal"
            id="project"
            ref="project"
            modal-class="bounce-modal"
            content-class="p-0 border-0"
            body-class="p-0"
            size="xl"
            @hide="selectedProject=null"
            hide-footer
            hide-header >
            <Mengede v-if="selectedProject==0" @close="modal=false;" />
        </b-modal>
    </div>

</template>

<script>
export default {
    name: "Portfolio",
    components: {
        Mengede: () => import('../portfolio/mengede.vue'),

    },
    data() {
        return{
            modal: false,
            selected: null,
            selectedProject: null,
        }
    },
    mounted() {
        this.selected = 0;
    },
    methods: {
        changeSelected(id) {
            if(this.selected != id){
                this.selected = null;
                setTimeout(()=>{
                    this.selected = id;
                }, 10);
            }else {
                this.selected = null;
            }
        }        
    }
}
</script>

<style>
.leaf-card-2 {
  border-radius: 2.5em;
  border-top-right-radius: .5em;
  border-bottom-left-radius: .5em;
  transition: all .25s;
  cursor: pointer;
}
.leaf-card-2:hover {
  transform: translateY(-.5em);
}
</style>