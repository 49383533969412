<template>

    <div class="col-12 mx-auto my-md-0 px-0">
        
        <div class="section d-flex align-items-center justify-content-center flex-wrap bg-primary-gradient text-white py-5 min-vh-100">
            <div class="mx-3 my-5 w-100" style="max-width: 35em;">
                <b-img src="@/assets/uiux.svg" class="col-12" />
            </div>
            <b-card
                bg-variant="transparent"
                style="max-width: 600px; min-width: 360px; "
                class="border-0 text-left mx-4 mx-md-2" >
                <b-card-title class="header-1 mb-4"> Your exclusive <br> UI/UX design </b-card-title>
                <b-card-text class="my-4">
                    We create an engaging wrapping for your ideas! This is where the creative magic of 
                    UI/UX design begins - from concept to a full-fledged package of unique design attributes.
                </b-card-text>

                <b-button v-b-toggle.contact-sidebar variant="transparent" class="outline-light rounded-custom-5 my-auto font-weight-bold small px-3"> Contact Us </b-button>
            </b-card>
        </div>

        <div class="section d-flex flex-column align-items-center justify-content-center flex-wrap py-5 bg-white">
            <p class="header-1 py-0 px-3">Start of design development at any stage</p>
            <p class="w-75 mb-5">
                From idea to conceptualization, from wireframes to graphics - 
                <b>Derash</b> is ready to get on board with your project at any moment of its development 
                lifecycle with our unique web or mobile UI/UX design services.
            </p>

            <div class="d-flex align-items-center justify-content-center flex-wrap">

                <b-list-group class="mx-auto mx-md-5">
                    <b-list-group-item @click="selected=0" :active="selected==0" :class="{'bg-primary': selected==0}" class="btn border-0 d-flex flex-row align-items-start my-1" style="border-radius: 1em">
                        <div class="d-flex align-items-center mr-3 bg-white" style="width:4em; height:4em; border-radius:50%;">
                            <i class="fa fa-compass w-100" :class="{'text-primary': selected==0}" style="font-size: 2.5em;"></i>
                        </div>
                        <div class="d-flex flex-column">
                            <span class="h5">Discovery & Strategy</span>
                            <span class="text-left">Stage 1</span>
                        </div>
                    </b-list-group-item>
                    
                    <b-list-group-item @click="selected=1"  :active="selected==1" :class="{'bg-primary': selected==1}" class="btn border-0 d-flex flex-row align-items-start my-1" style="border-radius: 1em">
                        <div class="d-flex align-items-center mr-3 bg-white" style="width:4em; height:4em; border-radius:50%;">
                            <i class="fa fa-th-large w-100" :class="{'text-primary': selected==1}" style="font-size: 2.5em;"></i>
                        </div>
                        <div class="d-flex flex-column">
                            <span class="h5">Solution Wireframing</span>
                            <span class="text-left">Stage 2</span>
                        </div>
                    </b-list-group-item>
                    
                    <b-list-group-item @click="selected=2" :active="selected==2" :class="{'bg-primary': selected==2}" class="btn border-0 d-flex flex-row align-items-start my-1" style="border-radius: 1em">
                        <div class="d-flex align-items-center mr-3 bg-white" style="width:4em; height:4em; border-radius:50%;">
                            <i class="fa fa-palette w-100" :class="{'text-primary': selected==2}" style="font-size: 2.5em;"></i>
                        </div>
                        <div class="d-flex flex-column">
                            <span class="h5">UI Design</span>
                            <span class="text-left">Stage 3</span>
                        </div>
                    </b-list-group-item>
                    
                    <b-list-group-item @click="selected=3" :active="selected==3" :class="{'bg-primary': selected==3}" class="btn border-0 d-flex flex-row align-items-start my-1" style="border-radius: 1em">
                        <div class="d-flex align-items-center mr-3 bg-white" style="width:4em; height:4em; border-radius:50%;">
                            <i class="fa fa-comments w-100" :class="{'text-primary': selected==3}" style="font-size: 2.5em;"></i>
                        </div>
                        <div class="d-flex flex-column">
                            <span class="h5">User Validation</span>
                            <span class="text-left">Stage 4</span>
                        </div>
                    </b-list-group-item>
                    
                    <b-list-group-item @click="selected=4" :active="selected==4" :class="{'bg-primary': selected==4}" class="btn border-0 d-flex flex-row align-items-start my-1" style="border-radius: 1em">
                        <div class="d-flex align-items-center mr-3 bg-white" style="width:4em; height:4em; border-radius:50%;">
                            <i class="fa fa-wrench w-100" :class="{'text-primary': selected==4}" style="font-size: 2.5em;"></i>
                        </div>
                        <div class="d-flex flex-column">
                            <span class="h5">Guides & Measurement Plan</span>
                            <span class="text-left">Stage 5</span>
                        </div>
                    </b-list-group-item>
                    
                    <b-list-group-item @click="selected=5" :active="selected==5" :class="{'bg-primary': selected==5}" class="btn border-0 d-flex flex-row align-items-start my-1" style="border-radius: 1em">
                        <div class="d-flex align-items-center mr-3 bg-white" style="width:4em; height:4em; border-radius:50%;">
                            <i class="fa fa-rocket w-100" :class="{'text-primary': selected==5}" style="font-size: 2.5em;"></i>
                        </div>
                        <div class="d-flex flex-column">
                            <span class="h5">Design Review</span>
                            <span class="text-left">Stage 6</span>
                        </div>
                    </b-list-group-item>
                </b-list-group>

                <b-card
                    v-if="selected==0"
                    bg-variant="transparent"
                    style="max-width: 600px;"
                    class="border-0 shadow leaf-card text-left mx-3 mx-md-5" >
                    <div class="d-flex mb-3">
                        <span class="h1 px-3 font-weight-bold text-muted"> 01 </span>
                        <div class="mx-3">
                            <div class="header-2">Acceleration & Collaboration</div>
                            <div class="small py-3 border-bottom border-dark">
                                We start with initial analytics and research (UI/UX audit), afterwards we conduct 
                                joint workshops together with you in order to form a common vision on Competitors 
                                Context, Service Personas, Current and Target Customer Journey maps, 
                                Design Principles, Short and Long term Product phases with a clear 
                                plan and future perspectives.
                            </div>
                        </div>
                    </div>
                    <div class="d-flex mb-3">
                        <span class="h1 px-3 font-weight-bold text-muted"> 02 </span>
                        <div class="mx-3">
                            <div class="header-2">UX Research</div>
                            <div class="small py-3 border-bottom border-dark">
                                During the UX research we will create the so-called proto-personas that will help 
                                set the structure and priorities of the Customer Journey, where we will also 
                                determine the initial opportunities and perspectives. Based on this information, 
                                later assumptions about users will be validated within a deep qualitative research.
                            </div>
                        </div>
                    </div>
                    <div class="d-flex mb-3">
                        <span class="h1 px-3 font-weight-bold text-muted"> <i class="fa fa-boxes text-primary"></i> </span>
                        <div class="mx-3">
                            <div class="header-2">What you get:</div>
                            <div class="small py-3">
                                <div> <i class="fa fa-clone mr-2 my-2 text-primary"></i> UX artifacts </div>
                                <div> <i class="fa fa-chart-bar mr-2 my-2 text-primary"></i> Web-analysis </div>
                                <div> <i class="fa fa-th-large mr-2 my-2 text-primary"></i> Wireframes </div>
                            </div>
                        </div>
                    </div>
                </b-card>
                
                <b-card
                    v-if="selected==1"
                    bg-variant="transparent"
                    style="max-width: 600px;"
                    class="border-0 shadow leaf-card text-left mx-3 mx-md-5" >
                    <div class="d-flex mb-3">
                        <span class="h1 px-3 font-weight-bold text-muted"> 01 </span>
                        <div class="mx-3">
                            <div class="header-2">Information architecture</div>
                            <div class="small py-3 border-bottom border-dark">
                                This is one of the most important functional parts of UI/UX design services - 
                                a hierarchical structure of the future app’s screens with description. 
                                Information architecture’s goal is to organize content in a way that 
                                is easy for future users to adapt to the functionality of the product 
                                and so that they could effortlessly navigate.
                            </div>
                        </div>
                    </div>
                    <div class="d-flex mb-3">
                        <span class="h1 px-3 font-weight-bold text-muted"> 02 </span>
                        <div class="mx-3">
                            <div class="header-2">Proof of concept and Stylistic Solution</div>
                            <div class="small py-3 border-bottom border-dark">
                                Development and approval of the product’s ideological concept. This stage 
                                includes collection and implementation of the requirements in terms of 
                                visual component, and User Experience consulting. I.e., as a web and mobile 
                                app design company, we are shaping a high-level vision of the application.
                            </div>
                        </div>
                    </div>
                    <div class="d-flex mb-3">
                        <span class="h1 px-3 font-weight-bold text-muted"> <i class="fa fa-boxes text-primary"></i> </span>
                        <div class="mx-3">
                            <div class="header-2">What you get:</div>
                            <div class="small py-3">
                                <div> <i class="fa fa-sitemap mr-2 my-2 text-primary"></i> Information architecture </div>
                                <div> <i class="fa fa-hand-point-up mr-2 my-2 text-primary"></i> Interactive conceptual prototype </div>
                            </div>
                        </div>
                    </div>
                </b-card>
                
                <b-card
                    v-if="selected==2"
                    bg-variant="transparent"
                    style="max-width: 600px;"
                    class="border-0 shadow leaf-card text-left mx-3 mx-md-5" >
                    <div class="d-flex mb-3">
                        <span class="h1 px-3 font-weight-bold text-muted"> 01 </span>
                        <div class="mx-3">
                            <div class="header-2">UI & Visual Design</div>
                            <div class="small py-3 border-bottom border-dark">
                                The start of development of specific working interface components: 
                                text fields, buttons, controllers, icons, illustrations and all 
                                the other elements future users will see and interact with.
                            </div>
                        </div>
                    </div>
                    <div class="d-flex mb-3">
                        <span class="h1 px-3 font-weight-bold text-muted"> 02 </span>
                        <div class="mx-3">
                            <div class="header-2">Visual Prototype</div>
                            <div class="small py-3 border-bottom border-dark">
                                All the visual components we created will be organized in a proper 
                                way according to the product’s information architecture, and made 
                                interactive and clickable. Thus, the stakeholders will have an 
                                opportunity to “catch a glimpse” of the future app in action 
                                even before the coding process itself starts.
                            </div>
                        </div>
                    </div>
                    <div class="d-flex mb-3">
                        <span class="h1 px-3 font-weight-bold text-muted"> <i class="fa fa-boxes text-primary"></i> </span>
                        <div class="mx-3">
                            <div class="header-2">What you get:</div>
                            <div class="small py-3">
                                <div> <i class="fa fa-book-open mr-2 my-2 text-primary"></i> Basic UI components library </div>
                                <div> <i class="fa fa-hand-point-up mr-2 my-2 text-primary"></i> Detailed visual interactive prototype </div>
                            </div>
                        </div>
                    </div>
                </b-card>
                
                <b-card
                    v-if="selected==3"
                    bg-variant="transparent"
                    style="max-width: 600px;"
                    class="border-0 shadow leaf-card text-left mx-3 mx-md-5" >
                    <div class="d-flex mb-3">
                        <span class="h1 px-3 font-weight-bold text-muted"> 01 </span>
                        <div class="mx-3">
                            <div class="header-2">User Testing</div>
                            <div class="small py-3 border-bottom border-dark">
                                We conduct user testing sessions, where the chosen representatives will 
                                be interacting with the visual prototype created during the previous 
                                stage. The main goal is to make sure that users feel comfortable 
                                with the chosen design concept and need no help in navigation.
                            </div>
                        </div>
                    </div>
                    <div class="d-flex mb-3">
                        <span class="h1 px-3 font-weight-bold text-muted"> 02 </span>
                        <div class="mx-3">
                            <div class="header-2">UI & Visual Design Update</div>
                            <div class="small py-3 border-bottom border-dark">
                                The results of user testing session are being documented. Based on the results 
                                we make a decision on what should be updated, or replaced and corrected, 
                                and ,consequently, implement the changes if there is a necessity in any.
                            </div>
                        </div>
                    </div>
                    <div class="d-flex mb-3">
                        <span class="h1 px-3 font-weight-bold text-muted"> <i class="fa fa-boxes text-primary"></i> </span>
                        <div class="mx-3">
                            <div class="header-2">What you get:</div>
                            <div class="small py-3">
                                <div class="d-flex"> 
                                    <i class="fa fa-file-alt mr-3 my-2 text-primary" style="font-size: 1.25em;"></i> 
                                    <p>
                                        Detailed documented UI/UX testing process, starting from the user 
                                        flow and ending with the design update recommendations
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </b-card>
                
                <b-card
                    v-if="selected==4"
                    bg-variant="transparent"
                    style="max-width: 600px;"
                    class="border-0 shadow leaf-card text-left mx-3 mx-md-5" >
                    <div class="d-flex mb-3">
                        <span class="h1 px-3 font-weight-bold text-muted"> 01 </span>
                        <div class="mx-3">
                            <div class="header-2">UI Kit & Digital Styleguide</div>
                            <div class="small py-3 border-bottom border-dark">
                                This is where the coding and technical stuff starts. Once we’ve implemented all 
                                the necessary adjustments to the visual components of the product, the design 
                                team forms full-fledged UI Kit that includes all the assets and artifacts that 
                                the development team will work with, and a Styleguide which is a detailed 
                                “instruction” on how to utilize the UI Kit components.
                            </div>
                        </div>
                    </div>
                    <div class="d-flex mb-3">
                        <span class="h1 px-3 font-weight-bold text-muted"> 02 </span>
                        <div class="mx-3">
                            <div class="header-2">Marketing Materials and Web Analytics</div>
                            <div class="small py-3 border-bottom border-dark">
                                Comprises recommendations on business and marketing actions aimed at the product’s 
                                future stable vitality after its release.
                            </div>
                        </div>
                    </div>
                    <div class="d-flex mb-3">
                        <span class="h1 px-3 font-weight-bold text-muted"> <i class="fa fa-boxes text-primary"></i> </span>
                        <div class="mx-3">
                            <div class="header-2">What you get:</div>
                            <div class="small py-3">
                                <div> <i class="fa fa-clone mr-2 my-2 text-primary"></i> UI documentation </div>
                                <div> <i class="fa fa-chart-bar mr-2 my-2 text-primary"></i> Marketing analysis </div>
                            </div>
                        </div>
                    </div>
                </b-card>
                
                <b-card
                    v-if="selected==5"
                    bg-variant="transparent"
                    style="max-width: 600px;"
                    class="border-0 shadow leaf-card text-left mx-3 mx-md-5" >
                    <div class="d-flex mb-3">
                        <span class="h1 px-3 font-weight-bold text-muted"> 01 </span>
                        <div class="mx-3">
                            <div class="header-2">Development Support and Design Review</div>
                            <div class="small py-3 border-bottom border-dark">
                                The design team is providing maintenance, guidance and control over the product 
                                development team in order to ensure the correct adherence to the Styleguide.
                            </div>
                        </div>
                    </div>
                    <div class="d-flex mb-3">
                        <span class="h1 px-3 font-weight-bold text-muted"> <i class="fa fa-boxes text-primary"></i> </span>
                        <div class="mx-3">
                            <div class="header-2">What you get:</div>
                            <div class="small py-3">
                                <div> <i class="fa fa-life-ring mr-2 my-2 text-primary"></i> Full support </div>
                                <div> <i class="fa fa-thumbs-up mr-2 my-2 text-primary"></i> Prompt design updates in case of user dissatisfaction </div>
                            </div>
                        </div>
                    </div>
                </b-card>
            </div>
        </div>
        
        <div class="section d-flex align-items-center justify-content-center flex-wrap bg-primary-gradient text-white py-5 min-vh-100">
            <b-card
                bg-variant="transparent"
                style="max-width: 500px; min-width: 360px; "
                class="border-0 text-left mx-4 mx-md-2" >
                <b-card-title class="header-1 mb-4"> UI/UX Design </b-card-title>
                <b-card-text class="my-4">
                    We create design solutions for various types of apps. Our experts work by focusing 
                    on the needs of the end-users, making it a key part of the entire process of app development.
                </b-card-text>
                <b-card-text class="my-2">
                    <div> <i style="width: 1em;" class="fa fa-grip-vertical mr-2 my-2 "></i> Smooth design approval workflow </div>
                    <div> <i style="width: 1em;" class="fa fa-clock mr-2 my-2 "></i> 3 weeks from initial idea to prototype </div>
                    <div> <i style="width: 1em;" class="fa fa-hand-point-up mr-2 my-2 "></i> Interactive prototyping </div>
                    <div> <i style="width: 1em;" class="fa fa-clone mr-2 my-2 "></i> 100% compliance with UI/UX guidelines </div>
                </b-card-text>
            </b-card>
            <div class="mx-3 my-5 w-100" style="max-width: 35em;">
                <b-img src="@/assets/uiux_2.svg" class="col-12" />
            </div>
        </div>

    </div>

</template>

<script>
export default {
    name: "UIUX",
    data() {
        return{
            selected: 0,            
        }
    },
    mounted() {

    },
    methods: {
        
    }
}
</script>

<style>

</style>