<template>

    <div class="mx-auto" >
        
        <div class="d-flex flex-column align-items-stretch bg-white shadow">
            
            <div class="bg-primary d-flex">
                <div class="flex-grow-1 d-flex flex-column">
                    <span class="pt-3 px-3 font-weight-bold text-right text-white" v-if="mDate"> {{calendar.local.dayNames[mDate.dayOfWeek()]}}, {{months[mDate.month()-1]}} {{mDate.day()}}, {{mDate.year()}}</span>
                    <div class="py-3 flex-grow-1 d-flex align-items-center justify-content-center">
                        <i class="flex-grow-1 text-white fa fa-arrow-left mx-auto p-2 py-3" style="font-size:1.5em;" @click="yearBefore()"></i>
                        <i class="flex-grow-1 text-white fa fa-chevron-left mx-auto p-2 py-3" style="font-size:1.5em;" @click="monthBefore()"></i>
                        <i class="flex-grow-1 text-white fa fa-calendar mx-auto p-2 py-3" style="font-size:1.5em;" @click="currentDate()"></i>
                        <i class="flex-grow-1 text-white fa fa-chevron-right mx-auto p-2 py-3" style="font-size:1.5em;" @click="monthAfter()"></i>
                        <i class="flex-grow-1 text-white fa fa-arrow-right mx-auto p-2 py-3" style="font-size:1.5em;" @click="yearAfter()"></i>
                    </div>
                </div>
            </div>
            <table class="m-0">
                <thead class="shadow-sm">
                    <transition name="slide-fade" mode="out-in">
                        <tr class="font-weight-bold text-muted text-center" :key="year+month">
                            <td colspan="7" class="pt-3"> 

                                <div class="d-flex align-items-center px-3 justify-content-between">
                                    <span class="font-weight-bold text-right" v-if="mDate"> {{months[month-1]}} {{year}} </span>
                                    <b-form-checkbox 
                                        v-model="calendarType" 
                                        value="EC" 
                                        unchecked-value="GC" 
                                        @change="init();" 
                                        size="sm" 
                                        button
                                        button-variant="transparent p-0 pb-2 text-muted"
                                        class="d-flex align-items-center p-0 " > 
                                        <span class="small font-weight-bold" v-text="calendarType=='GC'? 'የኢትዮጵያ':'Gregorian'"></span> 
                                    </b-form-checkbox>
                                </div>
                            </td>
                        </tr>
                    </transition>
                    <transition name="slide-fade" mode="out-in">
                        <tr :key="year+month">
                            <td class="border-bottom small text-muted p-2" style="min-width: 4em;" v-for="(value, index) in days" :key="index"> {{value}}</td>
                        </tr>
                    </transition>
                </thead>
                <tbody>
                    <tr v-for="(row, idr) in Math.ceil(daysInMonth/7)" :key="idr">
                        <td :class="{'bg-light text-muted': !validDate(new Date(year, month, ((row-1)*7+item)))}" 
                            v-for="(item, idc) in 7" 
                            :key="idc" > 
                            <span 
                                :class="{
                                    'font-weight-bold bg-primary text-white': ((row-1)*7+item)==mDate.day() && year==mDate.year() && month==mDate.month(),
                                }"
                                class="text-center font-weight-bold d-block my-2 mx-auto text-center"
                                style="user-select:none; cursor: pointer; line-height: 30px; height: 30px; width: 30px; border-radius: 50%;" 
                                :style="!validDate(new Date(year, month, ((row-1)*7+item))) ? {'cursor':'default', 'font-weight': 'normal !important'}:null"
                                v-if="((row-1)*7+item) <= daysInMonth"
                                @click="setDate(year, month, (row-1)*7+item)" > 
                                {{(row-1)*7 + item}} 
                            </span> 
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

    </div>

</template>
<script>
/* eslint-disable */
export default {
    name: "Calendar",
    props: ["date", "min", "max"],
    model: {
        prop: 'date',
        event: 'input'
    },
    data() {
        return{
            calendarType: 'EC',
            calendar: null,
            days: [],
            months: [],
            today: null,
            day: 1,
            month: 1,
            year: 2000,
            daysInMonth: 0,
            offset: 0,
            dayGrid: [],
            mDate: null,
        }
    },
    computed: {
        minConverted() {
            var tmp = this.min ? this.calendar.fromJSDate(this.min):null;
            return tmp;
        },
        maxConverted() {
            var tmp = this.max ? this.calendar.fromJSDate(this.max):null;
            return tmp;
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            // Instantiate calendar
            if(this.calendarType=="EC")
                this.calendar = $.calendars.instance('ethiopian', 'am');
            else 
                this.calendar = $.calendars.instance();

            // Convert passed date to custom type if present
            if(this.mDate) {
                var jsd =this.mDate.toJSDate();
                const offset = jsd.getTimezoneOffset();
                jsd = new Date(jsd.getTime() - (offset*60*1000));
                this.mDate = this.calendar.fromJSDate(jsd);
            }

            this.days = this.calendar.local.dayNamesShort;
            this.months = this.calendar.local.monthNames;

            this.today = this.calendar.today();
            this.year = this.mDate ?  this.mDate.year():this.today.year();
            this.month = this.mDate ?  this.mDate.month():this.today.month();
            this.day = this.mDate ?  this.mDate.day():this.today.day();
            if(!this.mDate)
                this.mDate = this.today;

            this.load();
        },
        monthBefore() {
            this.month--;
            if(this.month <= 0){
                this.month = this.months.length;
                this.year--;
            }
            this.load();
        },
        monthAfter() {
            this.month++;
            if(this.month > this.months.length) {
                this.month = 1;
                this.year++;
            }
            this.load();
        },
        yearBefore() {
            this.year--;
            this.load();
        },
        yearAfter() {
            this.year++;
            this.load();
        },
        currentDate() {
            this.mDate = this.calendar.today();
            this.year = this.today.year();
            this.day = this.today.day();
            this.month = this.today.month();
            this.load();
            this.$emit('input', this.mDate);
        },
        load() {
            this.daysInMonth = this.calendar.daysInMonth(this.year, this.month);
            this.offset = this.calendar.dayOfWeek(this.year, this.month, 1);
            this.rotate(this.offset);
        },
        rotate(start) {
            var tmp = [];
            for(var i=start; i<this.calendar.local.dayNamesShort.length; i++) {
                tmp.push(this.calendar.local.dayNamesShort[i]);
            }
            for(var i=0; i<start; i++) {
                tmp.push(this.calendar.local.dayNamesShort[i]);
            }
            this.days = tmp;
        },
        setDate(year, month, date){
            if(this.validDate(new Date(year, month, date)))
                this.mDate = this.calendar.newDate(year, month, date);
            this.$emit('input', this.mDate);
        },
        validDate(date) {
            var valid = true;
            if(this.min){
                var mn = new Date(this.minConverted.year(), this.minConverted.month(), this.minConverted.day());
                if(mn > date)
                    valid = false;
            }
            if(this.max) {
                var mx = new Date(this.maxConverted.year(), this.maxConverted.month(), this.maxConverted.day());
                if(date > mx)
                    valid = false;
            }
            return valid;
        },
    }
}
</script>

<style scoped>
.slide-fade-enter-active {
  transition: all .1s ease;
}
.slide-fade-leave-active {
  transition: all .1s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to{ 
  transform: translateX(10px);
  opacity: 0;
}
</style>